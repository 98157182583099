import axiosInstance from "auth/FetchInterceptor";
import { NHAPKHO_API } from "constants/ApiConstant";
const NhapKhoService = {};

NhapKhoService.getNhapKhoCTByManx = function (data) {
  return axiosInstance.post(`${NHAPKHO_API}/get-ct-by-manx`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

NhapKhoService.addNhapKhoCT = function (data) {
  return axiosInstance.post(`${NHAPKHO_API}/add-nhapkhoct`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
NhapKhoService.updateNhapKhoCT = function (data) {
  return axiosInstance.post(`${NHAPKHO_API}/update-nhapkhoct`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
NhapKhoService.deleteNhapKhoCT = function (id) {
  return axiosInstance.delete(`${NHAPKHO_API}/delete-nhapkhoct?manxct=${id}`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
export default NhapKhoService;
