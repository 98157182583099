import {
  CREATE_CHUC_VU_CATEGORY_API,
  DELETE_CHUC_VU_CATEGORY_API,
  GET_CHUC_VU_CATEGORY_API,
  UPDATE_CHUC_VU_CATEGORY_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const chucVuCategoryService = {};

chucVuCategoryService.Get = function () {
  return axiosInstance.get(`${GET_CHUC_VU_CATEGORY_API}`);
};
chucVuCategoryService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_CHUC_VU_CATEGORY_API}`, data);
};
chucVuCategoryService.Create = function (data) {
  return axiosInstance.post(`${CREATE_CHUC_VU_CATEGORY_API}`, data);
};
chucVuCategoryService.Delete = function (data) {
  return axiosInstance.delete(`${DELETE_CHUC_VU_CATEGORY_API}?id=${data}`);
};

export default chucVuCategoryService;
