import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BaoCaoThuChiService from "services/Finance/BaoCaoThuChiService";

export const GetSoDuTaiKhoan = createAsyncThunk(
  "baoCaoThuChiSlice/GetSoDuTaiKhoan",
  async (data, { rejectWithValue }) => {
    try {
      const response = await BaoCaoThuChiService.GetSoDuTaiKhoan(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  SoDuTaiKhoanList: [],
};

const baoCaoThuChiSlice = createSlice({
  name: "baoCaoThuChiSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetSoDuTaiKhoan.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetSoDuTaiKhoan.fulfilled, (state, action) => {
        state.loading = false;
        state.SoDuTaiKhoanList = action.payload;
      })
      .addCase(GetSoDuTaiKhoan.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const {} = baoCaoThuChiSlice.actions;

export default baoCaoThuChiSlice.reducer;
