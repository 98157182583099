import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import saleChangeHistoryService from "services/sale/SaleChangeHistoryService";

export const getSaleChangeHistory = createAsyncThunk(
  "salechangehistory/getSaleChangeHistory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await saleChangeHistoryService.getSaleChangeHistory(
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  saleChangeHistoryList: [],
};

export const saleChangeHistorySlice = createSlice({
  name: "salechangehistory",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSaleChangeHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSaleChangeHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.saleChangeHistoryList = action.payload;
      })
      .addCase(getSaleChangeHistory.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = saleChangeHistorySlice.actions;

export default saleChangeHistorySlice.reducer;
