import { Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import TableDmkkList from "./TableDmkkList";
import { useDispatch, useSelector } from "react-redux";
// import Utils from "utils";
import { getAllDmKk, getDmLoaiKk } from "store/slices/sale/danhmucSlice";
// import { PermitValue, permitKey } from "constants";
import moment from "moment";
import MeditechTablePage, { FilterComponent } from "components/table-layout";
const initSearch = {
  startDate: moment().date(-30), //.startOf("month"),
  endDate: moment().endOf("month"),
  searchText: null,
  maLoaiKk: 1,
};
export const initDmkk = {
  action: "initial",
  isRequired: true,
};
const DmkkList = () => {
  const dispatch = useDispatch();
  const { permitList } = useSelector((state) => state.auth);
  const { allDmKkList, loading, dmLoaiKkList } = useSelector(
    (state) => state.danhMucSale
  );
  useEffect(() => {
    dispatch(getDmLoaiKk());
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const reloadData = () => {
    searchFormRef.current = { ...searchFormRef.current };
    dispatch(getAllDmKk(searchFormRef.current));
  };

  const searchFormRef = useRef({ ...initSearch });
  const [searchFormData, setSearchFormData] = useState(searchFormRef.current);
  const searchHandle = (dataSearch) => {
    searchFormRef.current = { ...searchFormRef.current, ...dataSearch };
    setSearchFormData({ ...searchFormRef.current });
    reloadData();
  };

  // const allowAddNew = Utils.checkPermitValue(
  //   PermitValue.them,
  //   permitList,
  //   permitKey.crm_hospital
  // );

  const onRenderSearchView = () => {
    return (
      <FilterComponent
        datasource={searchFormData}
        onSearchImmediate={searchHandle}
        // renderFilterMoreView={renderFilterMoreView}
        renderInputSearch
        renderDatePicker={false}
      >
        <Select
          key="maLoaiKk"
          data-field="maLoaiKk"
          style={{ width: "100%", minWidth: 200 }}
          placeholder="Loại kho"
          options={dmLoaiKkList.map((m) => ({
            value: m.value,
            label: m.label,
          }))}
        />
      </FilterComponent>
    );
  };

  // const onDeleteHandler = (id) => {
  //   // dispatch(
  //   //   DeletePhuongAnKinhDoanh({
  //   //     id: id,
  //   //     onSuccess: ({ data }) => {
  //   //       if (data.isOk) {
  //   //         reloadData();
  //   //         notification.info({
  //   //           message: "Xóa phương án kinh doanh",
  //   //           description: "Xóa phiếu thành công",
  //   //         });
  //   //       } else {
  //   //         notification.error({
  //   //           message: "Xóa phương án kinh doanh",
  //   //           description: data.description,
  //   //         });
  //   //       }
  //   //     },
  //   //   })
  //   // );
  // };

  // const onOpenDetailHandler = (id) => {
  //   // setPhuongAnId(id);
  //   // setVisibleModal(true);
  // };

  const getRightButton = (isLocked) => {
    const arr = [];
    if (isLocked) {
      // arr.push({
      //   keyName: SystemButtonSource.addBtn,
      //   title: "Thêm",
      //   showMore: false,
      //   // disable: !allowAddNew,
      // });
    }
    return arr;
  };

  const onRightTopBtnHandler = (key) => {
    // switch (key) {
    //   case SystemButtonSource.addBtn:
    //     setVisibleModal((prev) => !prev);
    //     break;
    //   default:
    //     break;
    // }
  };
  return (
    <>
      <MeditechTablePage
        tableTitle="DANH MỤC KHO - NCC"
        btnHeaderRightSource={getRightButton(true)}
        btnHeaderRightSourceHandle={onRightTopBtnHandler}
        onRenderSearchView={onRenderSearchView}
      >
        <TableDmkkList
          loading={loading}
          allDmKkList={allDmKkList}
          reloadData={() => reloadData()}
          permitList={permitList}
          maLoaiKk={searchFormData.maLoaiKk}
        />
      </MeditechTablePage>
    </>
  );
};

export default DmkkList;
