import axiosInstance from "auth/FetchInterceptor";
import { QUAN_LY_VAN_BAN_API} from "constants/ApiConstant";
const QLCVService = {};
QLCVService.getQlCvById = function (id) {
  return axiosInstance.get(`${QUAN_LY_VAN_BAN_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
QLCVService.deleteQlCvById = function (id) {
  return axiosInstance.delete(`${QUAN_LY_VAN_BAN_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
//#region  công văn, văn bản
QLCVService.getAllCongVanDen = function (data) {
    return axiosInstance.post(`${QUAN_LY_VAN_BAN_API}/search`,data, {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.updateCongVanDen = function (data) {
    
    return axiosInstance.put(`${QUAN_LY_VAN_BAN_API}/update-van-ban`, data,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };

  QLCVService.insertVanBan =async function (data) {
    console.log(data,"data")
    return await axiosInstance.post(`${QUAN_LY_VAN_BAN_API}/add-van-ban`, data,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };

  QLCVService.updateDaXemCongVan = function (data) {
    
    return axiosInstance.put(`${QUAN_LY_VAN_BAN_API}/update-da-xem`, data,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };

  //#endregion
 

 //#region dm độ ưu tiên

  QLCVService.getDmDoUuTien = function (pageNumber,pageSize) {
    
    return axiosInstance.get(`${QUAN_LY_VAN_BAN_API}/get-dmdo-uu-tien`,{
      params:{pageNumber,pageSize},
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };

  QLCVService.deleteDmDoUuTienById = function (data) {
    return axiosInstance.delete(`${QUAN_LY_VAN_BAN_API}/delete-dm-do-uu-tien?id=${data}`,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.insertDmDoUuTien =async function (data) {
    
    return await axiosInstance.post(`${QUAN_LY_VAN_BAN_API}/add-dm-do-uu-tien`, data,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.updateDmDoUuTien = function (data) {
    return axiosInstance.put(`${QUAN_LY_VAN_BAN_API}/update-dm-do-uu-tien`, data,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.getDmDoUuTienById = function (id) {
    return axiosInstance.get(`${QUAN_LY_VAN_BAN_API}/get-do-uu-tien-by-id?id=${id}`, {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
 //#endregion
  //#region  dm văn bản
  QLCVService.getDmVanBan = function (pageNumber,pageSize) {
    
    return axiosInstance.get(`${QUAN_LY_VAN_BAN_API}/get-dm-van-ban`,{
      params:{pageNumber,pageSize},
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.insertDmVanBan =async function (data) {
    
    return await axiosInstance.post(`${QUAN_LY_VAN_BAN_API}/add-dm-van-ban`, data,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.updateDmVanBan= function (data) {
    
    return axiosInstance.put(`${QUAN_LY_VAN_BAN_API}/update-dm-van-ban`, data,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.deleteDmVanBanById = function (data) {
    return axiosInstance.delete(`${QUAN_LY_VAN_BAN_API}/delete-dm-van-ban?id=${data}`,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.getDmVanBanById = function (data) {
    return axiosInstance.get(`${QUAN_LY_VAN_BAN_API}/get-dm-van-ban-by-id?id=${data}`,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };

  //#endregion
  //#region  dm hình thức gửi công văn văn bản
  QLCVService.getDmHinhThucGuiCongVan = function (pageNumber,pageSize) {
    
    return axiosInstance.get(`${QUAN_LY_VAN_BAN_API}/get-all-hinh-thuc-gui-cong-van`,{
      params:{pageNumber,pageSize},
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  
  QLCVService.deleteDmHinhThucGuiVanBanById = function (data) {
    return axiosInstance.delete(`${QUAN_LY_VAN_BAN_API}/delete-dm-hinh-thuc-gui-van-ban?id=${data}`,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.getDmHinhThucGuiVanBanById = function (data) {
    return axiosInstance.get(`${QUAN_LY_VAN_BAN_API}/get-hinh-thuc-by-id?id=${data}`,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };

  QLCVService.insertDmHinhThucGuiVanBan =async function (data) {
    
    return await axiosInstance.post(`${QUAN_LY_VAN_BAN_API}/add-dm-hinh-thuc-gui-van-ban`, data,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.updateDmHinhThucGuiVanBan= function (data) {
    
    return axiosInstance.put(`${QUAN_LY_VAN_BAN_API}/update-dm-hinh-thuc-gui-van-ban`, data,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };


  //#endregion
  //#region  dm nơi gửi nhận công văn văn bản
  QLCVService.getDmNoiGuiNhanCongVan = function (pageNumber,pageSize) {
    
    return axiosInstance.get(`${QUAN_LY_VAN_BAN_API}/get-dm-noi-gui-nhan-cong-van`,{
      params:{pageNumber,pageSize},
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.updateDmNoiGuiNhanCongVan = function (data) {
    
    return axiosInstance.put(`${QUAN_LY_VAN_BAN_API}/update-dm-noi-gui-nhan-van-ban`, data,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.insertDmNoiGuiNhanCongVan =async function (data) {
    
    return await axiosInstance.post(`${QUAN_LY_VAN_BAN_API}/add-dm-noi-gui-nhan-van-ban`, data,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.deleteDmNoiGuiNhanVanBanById = function (data) {
    return axiosInstance.delete(`${QUAN_LY_VAN_BAN_API}/delete-dm-noi-gui-nhan-van-ban?id=${data}`,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };

  QLCVService.getDmNoiGuiNhanVanBanById = function (data) {
    return axiosInstance.get(`${QUAN_LY_VAN_BAN_API}/get-noi-gui-nhan-van-ban-by-id?id=${data}`,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };

  //#endregion
  //#region  dm nơi lưu trữu công văn văn bản
  QLCVService.getDmNoiLuuTruCongVan =  function (pageNumber,pageSize) {
    
    return axiosInstance.get(`${QUAN_LY_VAN_BAN_API}/get-dm-noi-luu-tru-cong-van`,{
      params:{pageNumber,pageSize},
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.deleteDmNoiLuuTruById = function (data) {
    return axiosInstance.delete(`${QUAN_LY_VAN_BAN_API}/delete-dm-noi-luu-tru?id=${data}`,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.getDmNoiLuuTruById = function (data) {
    return axiosInstance.get(`${QUAN_LY_VAN_BAN_API}/get-noi-luu-tru-by-id?id=${data}`,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.insertDmNoiLuuTru =async function (data) {
    
    return await axiosInstance.post(`${QUAN_LY_VAN_BAN_API}/add-dm-noi-luu-tru`, data,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.updateDmNoiLuuTru = function (data) {
    return axiosInstance.put(`${QUAN_LY_VAN_BAN_API}/update-dm-noi-luu-tru`, data,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  QLCVService.getDmNoiLuuTruByBranchId = function (data) {
    return axiosInstance.get(`${QUAN_LY_VAN_BAN_API}/get-noi-luu-tru-by-branchid?id=${data}`,{
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    });
  };
  //#endregion

 
export default QLCVService;
