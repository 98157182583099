import axiosInstance from "auth/FetchInterceptor";

const { THIETLAP_LUONG_API } = require("constants/ApiConstant");

const ThietLapLuongService = {};

ThietLapLuongService.getAll = function (data) {
  return axiosInstance.get(`${THIETLAP_LUONG_API}/danh-sach`, data, {
    baseURL: process.env.REACT_APP_HRM_ENDPOINT_URL,
  });
};

ThietLapLuongService.thietlapphucapgiamtruGetAll = function (data) {
  return axiosInstance.get(`${THIETLAP_LUONG_API}/dm-phu-cap-giam-tru`, data, {
    baseURL: process.env.REACT_APP_HRM_ENDPOINT_URL,
  });
};
ThietLapLuongService.createThietLapPhuCapGiamTru = function (data) {
  return axiosInstance.post(`${THIETLAP_LUONG_API}/dm-phu-cap-giam-tru`, data, {
    baseURL: process.env.REACT_APP_HRM_ENDPOINT_URL,
  });
};

ThietLapLuongService.updateThietLapPhuCapGiamTru = function (data) {
  return axiosInstance.put(`${THIETLAP_LUONG_API}/dm-phu-cap-giam-tru`, data, {
    baseURL: process.env.REACT_APP_HRM_ENDPOINT_URL,
  });
};

ThietLapLuongService.deleteThietLapPhuCapGiamTru = function (id) {
  return axiosInstance.delete(
    `${THIETLAP_LUONG_API}/dm-phu-cap-giam-tru?id=${id}`,
    {
      baseURL: process.env.REACT_APP_HRM_ENDPOINT_URL,
    }
  );
};

// ThietLapLuongService.getCommentById = function (id) {
//   return axiosInstance.get(`${COMMENT_API}/get-by-id?id=${id}`, {
//     baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
//   });
// };

// ThietLapLuongService.create = function (data) {
//   return axiosInstance.post(COMMENT_API, data, {
//     baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
//   });
// };

// CommentService.update = function (data) {
//   return axiosInstance.put(`${COMMENT_API}/update`, data, {
//     baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
//   });
// };

// CommentService.delete = function (id) {
//   return axiosInstance.delete(`${COMMENT_API}?id=${id}`, {
//     baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
//   });
// };

export default ThietLapLuongService;
