import {
  GET_TRAINING_PROGRAMS_API,
  UPDATE_TRAINING_PROGRAM_API,
  DELETE_TRAINING_PROGRAM_API,
  CREATE_TRAINING_PROGRAM_API,
  GET_TRAINING_PROGRAM_BY_ID_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const trainingProgramService = {};

trainingProgramService.Get = function (data) {
  return axiosInstance.post(`${GET_TRAINING_PROGRAMS_API}`, data);
};
trainingProgramService.GetById = function (data) {
  return axiosInstance.get(`${GET_TRAINING_PROGRAM_BY_ID_API}?id=${data}`);
};
trainingProgramService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_TRAINING_PROGRAM_API}`, data);
};
trainingProgramService.Create = function (data) {
  return axiosInstance.post(`${CREATE_TRAINING_PROGRAM_API}`, data);
};
trainingProgramService.Delete = function (data) {
  return axiosInstance.delete(`${DELETE_TRAINING_PROGRAM_API}?id=${data}`);
};

export default trainingProgramService;
