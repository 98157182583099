import {
  GET_TRAINING_EMPLOYEE_API,
  UPDATE_TRAINING_EMPLOYEE_API,
  GET_ALL_TRAINING_EMPLOYEE_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const trainingEmployeeService = {};

trainingEmployeeService.Get = function (data) {
  return axiosInstance.post(`${GET_TRAINING_EMPLOYEE_API}`, data);
};
trainingEmployeeService.GetAll = function (data) {
  return axiosInstance.post(`${GET_ALL_TRAINING_EMPLOYEE_API}`, data);
};
trainingEmployeeService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_TRAINING_EMPLOYEE_API}`, data);
};

export default trainingEmployeeService;
