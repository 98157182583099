import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import DeXuatThanhToanService from "services/Finance/DeXuatThanhToanService";

export const GetAllDeXuatThanhToan = createAsyncThunk(
  "DeXuatThanhToan/GetAllDeXuatThanhToan",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DeXuatThanhToanService.GetAllDeXuatThanhToan(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetDeXuatThanhToan = createAsyncThunk(
  "DeXuatThanhToan/GetDeXuatThanhToan",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DeXuatThanhToanService.GetDeXuatThanhToan(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertDeXuatThanhToan = createAsyncThunk(
  "DeXuatThanhToan/UpsertDeXuatThanhToan",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeXuatThanhToanService.UpsertDeXuatThanhToan(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeleteDeXuatThanhToan = createAsyncThunk(
  "DeXuatThanhToan/DeleteDeXuatThanhToan",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeXuatThanhToanService.DeleteDeXuatThanhToan(
        payload.id
      );
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const GetDeXuatThanhToanChiTiet = createAsyncThunk(
  "DeXuatThanhToan/GetDeXuatThanhToanChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DeXuatThanhToanService.GetDeXuatThanhToanChiTiet(
        data
      );
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertDeXuatThanhToanChiTiet = createAsyncThunk(
  "DeXuatThanhToan/UpsertDeXuatThanhToanChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response =
        await DeXuatThanhToanService.UpsertDeXuatThanhToanChiTiet(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeleteDeXuatThanhToanChiTiet = createAsyncThunk(
  "DeXuatThanhToan/DeleteDeXuatThanhToanChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response =
        await DeXuatThanhToanService.DeleteDeXuatThanhToanChiTiet(payload.id);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  DeXuatThanhToanList: [],
  DeXuatThanhToanDetail: {},
  DeXuatThanhToanChiTietList: [],
};

const DeXuatThanhToanTienSlice = createSlice({
  name: "DeXuatThanhToan",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //GetDeXuatThanhToanChiTiet
      .addCase(GetDeXuatThanhToanChiTiet.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetDeXuatThanhToanChiTiet.fulfilled, (state, action) => {
        state.loading = false;
        state.DeXuatThanhToanChiTietList = action.payload;
      })
      .addCase(GetDeXuatThanhToanChiTiet.rejected, (state) => {
        state.loading = false;
      })
      //GetDeXuatThanhToan
      .addCase(GetDeXuatThanhToan.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetDeXuatThanhToan.fulfilled, (state, action) => {
        state.loading = false;
        state.DeXuatThanhToanDetail = action.payload;
      })
      .addCase(GetDeXuatThanhToan.rejected, (state) => {
        state.loading = false;
      })
      //GetAllDeXuatThanhToan
      .addCase(GetAllDeXuatThanhToan.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllDeXuatThanhToan.fulfilled, (state, action) => {
        state.loading = false;
        state.DeXuatThanhToanList = action.payload;
      })
      .addCase(GetAllDeXuatThanhToan.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { showLoading, setHisInfo } = DeXuatThanhToanTienSlice.actions;

export default DeXuatThanhToanTienSlice.reducer;
