import { notification, Select } from "antd";

import React, { useEffect, useRef, useState } from "react";
import TableNhapKhoList from "./TableNhapKhoList";
import ModalNhapKho from "./ModalNhapKho";
import { useDispatch, useSelector } from "react-redux";
import { getDSNhapXuat } from "store/slices/sale/nhapxuatSlice";
import moment from "moment";
import { SystemButtonSource } from "constants";
import MeditechTablePage, { FilterComponent } from "components/table-layout";
import DocumentViewer from "constants/DocumentViewer";
import { getDmKk } from "store/slices/sale/danhmucSlice";

const initSearch = {
  startDate: moment().startOf("month"),
  endDate: moment().endOf("month"),
  searchText: null,
  loai: 1,
  luanchuyen: false,
  makk: null,
  makkgiao: null,
};
export const initNhapKhoCt = {
  action: "initial",
  isRequired: true,
};
const NhapKhoList = ({ queryId, openModalDetail }) => {
  const dispatch = useDispatch();
  const [visibleModal, setVisibleModal] = useState(false);
  const { permitList } = useSelector((state) => state.auth);
  const [nhapKhoId, setNhapKhoId] = useState(null);
  const { nhapXuatList, loading, nhapXuatDetail } = useSelector(
    (state) => state.nhapXuatSale
  );
  const searchFormRef = useRef({ ...initSearch });
  const [searchFormData, setSearchFormData] = useState(searchFormRef.current);
  const { nhapKhoCTList } = useSelector((state) => state.nhapKhoSale);
  const [isprinter, setIsPrinter] = useState(false);
  const [printData, setPrintData] = useState({});
  const [idPhieu, setIdPhieu] = useState(null);
  const { dmKkList } = useSelector((state) => state.danhMucSale);
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type, placement, message) => {
    api[type]({
      message: message,
      placement,
    });
  };
  useEffect(() => {
    if (openModalDetail) {
      setVisibleModal(openModalDetail);
      setIdPhieu(queryId);
    }
  }, [queryId, openModalDetail]);

  useEffect(() => {
    dispatch(getDmKk());
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const reloadData = () => {
    dispatch(getDSNhapXuat(searchFormRef.current));
  };

  const searchHandle = (dataSearch) => {
    searchFormRef.current = { ...searchFormRef.current, ...dataSearch };
    setSearchFormData({ ...searchFormRef.current });
    reloadData();
  };
  const onRenderSearchView = () => {
    return (
      <FilterComponent
        datasource={searchFormData}
        onSearchImmediate={searchHandle}
        renderInputSearch
        renderDatePicker
      >
        <Select
          key="makk"
          data-field="makk"
          style={{ width: "100%", minWidth: 280 }}
          placeholder="Kho nhập"
          options={dmKkList?.filter((x) => x.maloai === 1)}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
        <Select
          key="makkgiao"
          data-field="makkgiao"
          style={{ width: "100%", minWidth: 280 }}
          placeholder="Đơn vị giao"
          options={dmKkList}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      </FilterComponent>
    );
  };

  const onDeleteHandler = (id) => {};

  const onOpenDetailHandler = (id) => {
    //setDoanKhamId(id);
    setVisibleModal(true);
  };

  const getRightButton = (isLocked) => {
    const arr = [];
    if (isLocked) {
      arr.push({
        keyName: SystemButtonSource.addBtn,
        title: "Thêm",
        showMore: false,
        // disable: !allowAddNew,
      });
    }
    return arr;
  };

  const onRightTopBtnHandler = (key) => {
    switch (key) {
      case SystemButtonSource.addBtn:
        setVisibleModal((prev) => !prev);
        break;
      default:
        break;
    }
  };
  return (
    <>
      {contextHolder}
      <MeditechTablePage
        tableTitle="Quản lý nhập kho"
        btnHeaderRightSource={getRightButton(true)}
        btnHeaderRightSourceHandle={onRightTopBtnHandler}
        onRenderSearchView={onRenderSearchView}
      >
        <TableNhapKhoList
          loading={loading}
          setNhapKhoId={setNhapKhoId}
          nhapXuatList={nhapXuatList}
          reloadData={() => reloadData()}
          setVisibleModal={setVisibleModal}
          visibleModal={visibleModal}
          permitList={permitList}
          onOpenDetail={onOpenDetailHandler}
          onDelete={onDeleteHandler}
          setIsPrinter={setIsPrinter}
          setPrintData={setPrintData}
        />
      </MeditechTablePage>

      <ModalNhapKho
        visibleModal={visibleModal}
        loading={false}
        setVisibleModal={setVisibleModal}
        nhapKhoId={nhapKhoId}
        setNhapKhoId={setNhapKhoId}
        idPhieu={idPhieu}
        setIdPhieu={setIdPhieu}
        nhapXuatDetail={nhapXuatDetail}
        reloadData={() => reloadData()}
        permitList={permitList}
        nhapKhoCTList={nhapKhoCTList}
        openNotification={openNotification}
        dmKkList={dmKkList}
        setIsPrinter={setIsPrinter}
        setPrintData={setPrintData}
      />
      <DocumentViewer
        openModel={isprinter}
        setIsPrinter={setIsPrinter}
        soPhieu={printData.sophieu}
        idPhieu={printData.idphieu}
        setPrintData={setPrintData}
        param={{
          manx: (printData.manx || 0).toString(),
          ct: "2",
        }}
        tenMauIn={"NhapXuat.rptPhieuNhapKho"}
        loaiPhieu={"NhapXuat"}
        tieuDe={`In phiếu nhập ${printData.sophieu}`}
      />
    </>
  );
};

export default NhapKhoList;
