import axiosInstance from "auth/FetchInterceptor";
import { SANG_KIEN_Y_TUONG } from "constants/ApiConstant";

const SangKienYTuongService = {};

SangKienYTuongService.getCategory = function () {
  return axiosInstance.get(`${SANG_KIEN_Y_TUONG}/category`);
};

SangKienYTuongService.create = function (data) {
  return axiosInstance.post(`${SANG_KIEN_Y_TUONG}/create`, data);
};

SangKienYTuongService.search = function (data) {
  return axiosInstance.post(`${SANG_KIEN_Y_TUONG}/search`, data);
};

SangKienYTuongService.export = function (data) {
  return axiosInstance.post(`${SANG_KIEN_Y_TUONG}/export`, data);
};

export default SangKienYTuongService;
