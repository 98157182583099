import React from "react"

const OfficeSvg = (props) => (
    <span className="anticon">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={17}
            fill="none"
            {...props}
        >
            <path
                fill="#219737"
                d="M4.667 7.52H3.333v-6c0-.368.298-.666.667-.666h8c.369 0 .667.298.667.667v4h-1.334V2.187H4.667v5.334Z"
            />
            <path
                fill="#219737"
                fillRule="evenodd"
                d="M9.333 6.187h6c.369 0 .667.298.667.667v9.333a.666.666 0 0 1-.667.667H.667A.666.666 0 0 1 0 16.187V9.521c0-.37.298-.667.667-.667h6c.368 0 .666.298.666.667v6h1.334V6.854c0-.369.298-.667.666-.667Zm-6.666 8.667h2V13.52h-2v1.333Zm2-2.667h-2v-1.333h2v1.333Zm6.666 2.667h2V13.52h-2v1.333Zm2-2.667h-2v-1.333h2v1.333Zm-2-2.666h2V8.187h-2v1.334Z"
                clipRule="evenodd"
            />
        </svg>
    </span>
)
export default OfficeSvg
