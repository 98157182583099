import axiosInstance from "auth/FetchInterceptor";
import { TRAHANG_API } from "constants/ApiConstant";
const TraHangService = {};

TraHangService.searchGrid = function (data) {
  return axiosInstance.post(`${TRAHANG_API}/search-grid`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

TraHangService.getTraHangBymatrahang = function (id) {
  return axiosInstance.get(`${TRAHANG_API}/get-by-matrahang?matrahang=${id}`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
TraHangService.getPhieuBanHangByKhachHang = function (data) {
  return axiosInstance.get(
    `${TRAHANG_API}/get-by-phieubanhangbykhach?makhachhang=${data.id}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

TraHangService.getPhieuTraByPhieuBan = function (data) {
  return axiosInstance.get(
    `${TRAHANG_API}/get-by-phieutrabyphieuban?mabanhang=${data.id}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};
TraHangService.create = function (data) {
  return axiosInstance.post(TRAHANG_API, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
TraHangService.update = function (data) {
  return axiosInstance.put(TRAHANG_API, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
TraHangService.changeStateTraHang = function (data) {
  return axiosInstance.put(`${TRAHANG_API}/change-state`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
TraHangService.deleteTraHang = function (id) {
  return axiosInstance.delete(
    `${TRAHANG_API}/delete-by-matrahang?matrahang=${id}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};
TraHangService.getTraHangCTBymatrahang = function (data) {
  return axiosInstance.post(`${TRAHANG_API}/get-ct-by-matrahang`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

TraHangService.addTraHangCT = function (data) {
  return axiosInstance.post(`${TRAHANG_API}/trahangct`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
TraHangService.updateTraHangCT = function (data) {
  return axiosInstance.put(`${TRAHANG_API}/trahangct`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
TraHangService.deleteTraHangCT = function (data) {
  return axiosInstance.delete(`${TRAHANG_API}/trahangct`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
TraHangService.getTonTraHang = function (data) {
  return axiosInstance.post(`${TRAHANG_API}/get-ton-ban-hang`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
export default TraHangService;
