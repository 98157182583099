import {
    DE_NGHI_THU_TIEN_API,
  } from "constants/ApiConstant";
  import axiosInstance from "auth/FetchInterceptor";
  const deNghiThuTienService = {};
  
  deNghiThuTienService.Get = function (data) {
    return axiosInstance.post(`${DE_NGHI_THU_TIEN_API}`, data, {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    });
  };
  deNghiThuTienService.Create = function (data) {
    return axiosInstance.post(`${DE_NGHI_THU_TIEN_API}/dntt`, data, {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    });
  };
  deNghiThuTienService.GetById = function (id) {
    return axiosInstance.get(`${DE_NGHI_THU_TIEN_API}?id=${id}`, {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    });
  };
  deNghiThuTienService.GetDsCty = function (data) {
    return axiosInstance.get(`${DE_NGHI_THU_TIEN_API}/dscty`, {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    });
  };

  deNghiThuTienService.Update = function (data) {
    return axiosInstance.put(`${DE_NGHI_THU_TIEN_API}`, data, {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    });
  };
  deNghiThuTienService.Delete = function (id) {
    return axiosInstance.delete(`${DE_NGHI_THU_TIEN_API}?id=${id}`, {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    });
  };

  deNghiThuTienService.CreateChiTiet = function (data) {
    return axiosInstance.post(`${DE_NGHI_THU_TIEN_API}/dntt-chitiet`, data, {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    });
  };
  deNghiThuTienService.UpdateChiTiet = function (data) {
    return axiosInstance.put(`${DE_NGHI_THU_TIEN_API}/dntt-chitiet`, data, {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    });
  };
  deNghiThuTienService.DeleteChiTiet = function (id) {
    return axiosInstance.delete(`${DE_NGHI_THU_TIEN_API}/dntt-chitiet?id=${id}`, {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    });
  };
  deNghiThuTienService.GetDsChiTietByDeNghiThuTienId = function (id) {
    return axiosInstance.get(`${DE_NGHI_THU_TIEN_API}/dntt-chitiet-ds?id=${id}`, {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    });
  };

  deNghiThuTienService.CreatPhieuThu = function (data) {
    return axiosInstance.post(`${DE_NGHI_THU_TIEN_API}/phieu-thu`, data, {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    });
  };
  
  deNghiThuTienService.TinhTongTienDeNghiThu = function (id) {
    return axiosInstance.get(`${DE_NGHI_THU_TIEN_API}/dntt-chitiet/tong-tien?id=${id}`, {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    });
  };
 
  export default deNghiThuTienService;
  