import { Button, Popconfirm, Form, Spin } from "antd";

import React, { useMemo, useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  createDmthuocvattu,
  updateDmthuocvattu,
  deleteDmthuocvattu,
} from "store/slices/sale/danhmucSlice";
import moment from "moment";
import EditableCell from "components/EditableCell";
import Utils from "utils";
import MediTable from "components/Custom";
import { isEmpty } from "lodash";

const formater = new Intl.NumberFormat("vi-VN");
export const formatterNumber = (val) => {
  if (!val) return 0;
  return formater.format(val);
};

export const parserNumber = (val) => {
  if (!val) return 0;
  return Number.parseFloat(
    val.replace(/\$\s?|(\.*)/g, "").replace(/(,{1})/g, ".")
  ).toFixed(5);
};

const TableDmthuocvattuList = ({ allDmthuocvattuList, reloadData }) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState(null);
  const { allDmLoaiDvList, allDmdonvitinhList, loading } = useSelector(
    (state) => state.danhMucSale
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isEditing = (record) =>
    record.action === "initial"
      ? record.action === editingKey
      : record.mahh === editingKey;

  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const edit = (record) => {
    form.setFieldsValue({
      mahh: 0,
      tenhh: null,
      sapxep: null,
      maview: null,
      sodangky: null,
      hamluong: null,
      dongia: 0,
      serialnumber: null,
      madonvitinh: null,
      quycachdonggoi: null,
      nhathau: null,
      ttthau: null,
      nuocsanxuat: null,
      hangsanxuat: null,
      ...record,
    });
    setEditingKey(record.action === "initial" ? record.action : record.mahh);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = allDmthuocvattuList.slice(0);
      const index = newData.findIndex((item) =>
        item.mahh ? item.mahh === key : item.action === key
      );
      const item = newData[index];
      const payload = {
        mahh: item.mahh || 0,
        tenhh: item.tenhh,
        sapxep: item.sapxep,
        maloaidichvu: item.maloaidichvu,
        maview: item.maview || "",
        madonvitinh: item.madonvitinh,
        mahoatchat: item.mahoatchat,
        manhomthuoc: item.manhomthuoc,
        maduongdung: item.maduongdung,
        hamluong: item.hamluong,
        quycachdonggoi: item.quycachdonggoi,
        mahieu: item.mahieu,
        khongtinhton: item.khongtinhton,
        khongsudung: item.khongsudung,
        tengoc: item.tengoc,
        dongia: item.dongia,
        sodangky: item.sodangky || "",
        ngayapdung: item.ngayapdung || moment(),
        apdungden: item.apdungden,
        maduocquocgia: item.maduocquocgia,
        saphetton: item.saphetton,
        ...row,
        nuocsanxuat: !isEmpty(row.nuocsanxuat) ? row.nuocsanxuat : "",
        hangsanxuat: !isEmpty(row.hangsanxuat) ? row.hangsanxuat : "",
        onSuccess: () => {
          reloadData();
          setEditingKey(null);
        },
      };

      if (key === "initial") {
        dispatch(createDmthuocvattu(payload));
      } else {
        dispatch(updateDmthuocvattu(payload));
      }
    } catch (error) {}
  };

  const tableColumns = useMemo(
    () => [
      {
        fixed: "left",
        title: "STT",
        align: "center",
        width: 40,
        render: (_, __, index) => index + 1,
      },
      {
        fixed: "left",
        title: "Mã số",
        dataIndex: "mahh",
        width: 60,
      },
      {
        fixed: "left",
        title: "Mã view",
        dataIndex: "maview",
        editable: true,
        width: 100,
      },
      {
        fixed: "left",
        title: "Tên",
        dataIndex: "tenhh",
        editable: true,
        width: 250,
        isRequired: true,
      },
      {
        fixed: "left",
        title: "ĐVT",
        dataIndex: "madonvitinh",
        editable: true,
        isRequired: true,
        width: 100,
        inputType: "select",
        options: allDmdonvitinhList?.map((m) => ({
          value: m.madonvitinh,
          label: m.tendonvitinh,
        })),
        render: (value, record) => record.tenDonViTinh,
      },
      {
        title: "Loại",
        dataIndex: "maloaidichvu",
        editable: true,
        isRequired: true,
        inputType: "select",
        width: 150,
        options: allDmLoaiDvList?.map((m) => ({
          value: m.maloaidichvu,
          label: m.tenloaidichvu,
        })),
        render: (value, record) => record.tenLoaiDichVu,
      },
      {
        title: "Hàm lượng",
        dataIndex: "hamluong",
        editable: true,
        width: 150,
      },
      {
        title: "Đơn giá",
        dataIndex: "dongia",
        align: "right",
        editable: true,
        inputType: "number",
        width: 90,
        render: (e) => Utils.formatterNumber(e),
      },
      {
        title: "Số ĐK",
        dataIndex: "sodangky",
        editable: true,
        width: 150,
      },
      {
        title: "Nước SX",
        dataIndex: "nuocsanxuat",
        editable: true,
        width: 150,
      },
      {
        title: "Hãng SX",
        dataIndex: "hangsanxuat",
        editable: true,
        width: 350,
      },
      {
        title: "Nhà thầu",
        dataIndex: "nhathau",
        editable: true,
        width: 350,
      },
      {
        title: "TT thầu",
        dataIndex: "ttthau",
        editable: true,
        width: 150,
      },
      {
        title: "Quy cách",
        dataIndex: "quycachdonggoi",
        editable: true,
        width: 350,
      },
      {
        title: "SerialNumber",
        dataIndex: "serialnumber",
        editable: true,
        width: 90,
      },
      {
        fixed: "right",
        align: "center",
        dataIndex: "action",
        width: 120,
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <>
              <Button
                onClick={() => save(record?.mahh || "initial")}
                className="mr-2"
                icon={<SaveOutlined />}
                shape="circle"
              />
              <Button
                onClick={() => setEditingKey(null)}
                className="mr-2"
                icon={<CloseOutlined />}
                shape="circle"
              />
            </>
          ) : (
            <>
              <Button
                title={
                  record.action === "initial"
                    ? "Thêm thông tin"
                    : "Sửa thông tin"
                }
                disabled={editingKey !== null && record.mahh !== editingKey}
                onClick={() => edit(record)}
                className="mr-2"
                icon={
                  record.action === "initial" ? (
                    <PlusOutlined />
                  ) : (
                    <EditOutlined />
                  )
                }
                shape="circle"
              />
              {record.mahh && (
                <>
                  <Popconfirm
                    title="Bạn có muốn xóa không?"
                    placement="topLeft"
                    onConfirm={() => {
                      dispatch(
                        deleteDmthuocvattu({
                          id: record.mahh,
                          onSuccess: () => {
                            reloadData();
                          },
                        })
                      );
                    }}
                  >
                    <Button
                      title={"Xóa thông tin"}
                      className="mr-2"
                      icon={<DeleteOutlined type="primary" />}
                      shape="circle"
                      disabled={
                        editingKey !== null && record.mahh !== editingKey
                      }
                    />
                  </Popconfirm>
                </>
              )}
            </>
          );
        },
      },
    ],
    [
      allDmLoaiDvList,
      allDmdonvitinhList,
      edit,
      isEditing,
      save,
      dispatch,
      reloadData,
      editingKey,
    ]
  );

  const mergedColumns = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        inputType: col?.inputType,
        isRequired: col?.isRequired,

        options: col?.options,
      }),
    };
  });

  return (
    <Spin spinning={loading} tip={"Đang tải..."}>
      <Form form={form} component={false}>
        <MediTable
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          tableColumns={mergedColumns}
          dataSource={allDmthuocvattuList}
          scroll={{ x: "max-content" }}
          rowKey={(item) => item?.mahh}
        />
      </Form>
    </Spin>
  );
};

export default TableDmthuocvattuList;
