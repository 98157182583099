import axiosInstance from "auth/FetchInterceptor";
import { DON_XIN_NGHI_API, NGHI_PHEP_NAM_API, QUAN_LY_Nghi_API } from "constants/ApiConstant";
const QuanLyNghiService = {};

QuanLyNghiService.searchDonXinNghi = function (data) {
  return axiosInstance.post(`${DON_XIN_NGHI_API}/search`, data);
};

QuanLyNghiService.donXinNghiDetail = function (id) {
  return axiosInstance.get(`${DON_XIN_NGHI_API}/get-by-id?id=${id}`);
};

QuanLyNghiService.upSertDonXinNghi = function (data) {
  return axiosInstance.post(`${DON_XIN_NGHI_API}/upsert`, data);
};

QuanLyNghiService.employeeApprove = function (data) {
  return axiosInstance.put(`${DON_XIN_NGHI_API}/employee-approve`, data);
};

QuanLyNghiService.deleteDonXinNghi = function (id) {
  return axiosInstance.delete(`${DON_XIN_NGHI_API}/delete?id=${id}`);
};

QuanLyNghiService.searchNghiPhepNam = function (data) {
  return axiosInstance.post(`${NGHI_PHEP_NAM_API}/search`, data);
};

QuanLyNghiService.addNewNghiPhepNam = function (data) {
  return axiosInstance.post(NGHI_PHEP_NAM_API, data);
};

QuanLyNghiService.updateNghiPhepNam = function (data) {
  return axiosInstance.put(NGHI_PHEP_NAM_API, data);
};

QuanLyNghiService.updateNghiPhepNamByIds = function (data) {
  return axiosInstance.put(`${NGHI_PHEP_NAM_API}/update-by-ids`, data);
};

QuanLyNghiService.searchDonXacNhanCong = function (data) {
  return axiosInstance.post(`${QUAN_LY_Nghi_API}/DonXacNhanCong/search`, data);
};

QuanLyNghiService.donXacNhanCongDetail = function (id) {
  return axiosInstance.get(`${QUAN_LY_Nghi_API}/DonXacNhanCong/get-by-id?id=${id}`);
};

QuanLyNghiService.upSertDonXacNhanCong = function (data) {
  return axiosInstance.post(`${QUAN_LY_Nghi_API}/DonXacNhanCong/upsert`, data);
};

QuanLyNghiService.deleteDonXacNhanCong = function (id) {
  return axiosInstance.delete(`${QUAN_LY_Nghi_API}/DonXacNhanCong/delete?id=${id}`);
};

QuanLyNghiService.searchThietLapLyDoCate = function (data) {
  return axiosInstance.post(`${QUAN_LY_Nghi_API}/search-thiet-lap-ly-do`, data);
};

QuanLyNghiService.upSertThietLapLyDoCate = function (data) {
  return axiosInstance.post(`${QUAN_LY_Nghi_API}/up-sert-thiet-lap-ly-do`, data);
};

export default QuanLyNghiService;
