import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import thuChiCategoryService from "services/Finance/ThuChiCategoryService";
import { cloneDeep } from "lodash";

export const getCateThuChiTree = createAsyncThunk(
  "thuChiCategory/getCateThuChiTree",
  async (data, { rejectWithValue }) => {
    try {
      const response = await thuChiCategoryService.GetTree(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getCateThuChi = createAsyncThunk(
  "thuChiCategory/getCateThuChi",
  async (data, { rejectWithValue }) => {
    const { onSuccess, type } = data;
    try {
      const response = await thuChiCategoryService.GetCate(type);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateCateThuChi = createAsyncThunk(
  "thuChiCategory/updateCateThuChi",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await thuChiCategoryService.Update(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createCateThuChi = createAsyncThunk(
  "thuChiCategory/createCateThuChi",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await thuChiCategoryService.Create(data);
      if (onSuccess) onSuccess(response.data);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);

export const deleteCateThuChi = createAsyncThunk(
  "thuChiCategory/deleteCateThuChi",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await thuChiCategoryService.Delete(id);
      if (onSuccess) onSuccess(response);
      return id;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);
export const getCateThuChiById = createAsyncThunk(
  "thuChiCategory/getCateThuChiById",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await thuChiCategoryService.GetById(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  thuChiCateTree: [],
  thuChiCate: [],
  thuChiCateDetail: {
    loading: false,
    data: null,
  },
};

const thuChiCategorySlice = createSlice({
  name: "thuChiCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCateThuChi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCateThuChi.fulfilled, (state, action) => {
        state.loading = false;
        state.thuChiCate = action.payload;
      })
      .addCase(getCateThuChi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCateThuChiTree.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCateThuChiTree.fulfilled, (state, action) => {
        state.loading = false;
        state.thuChiCateTree = action.payload;
      })
      .addCase(getCateThuChiTree.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateCateThuChi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCateThuChi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateCateThuChi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createCateThuChi.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCateThuChi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createCateThuChi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteCateThuChi.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCateThuChi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteCateThuChi.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getCateThuChiById.pending, (state) => {
        state.thuChiCateDetail = { ...state.thuChiDetail, loading: true };
      })
      .addCase(getCateThuChiById.fulfilled, (state, action) => {
        state.thuChiCateDetail = { data: action.payload, loading: false };
      })
      .addCase(getCateThuChiById.rejected, (state) => {
        state.thuChiCateDetail = { ...state.thuChiDetail, loading: false };
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const {} = thuChiCategorySlice.actions;

export default thuChiCategorySlice.reducer;
