import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Spin,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  LockOutlined,
  UnlockOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewHSC1,
  changeStateHSC1,
  deleteHSC1,
  searchHSC1,
  updateHSC1,
} from "store/slices/hrRecordSlice";
import Utils from "utils";
import { PermitValue, permitKey } from "constants";

export const initHoSo = {
  action: "initial",
  isRequired: true,
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  const input =
    dataIndex === "sortNumber" ? (
      <InputNumber min={0} />
    ) : (
      <Input placeholder={title} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: ["name", "sortNumber"].includes(dataIndex),
              message: `Bắt buộc!`,
            },
          ]}
        >
          {input}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const HoSoCap1 = () => {
  const dispatch = useDispatch();
  const { loading, searchHs1 } = useSelector((state) => state.hrRecord);
  const { permitList } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isEditing = (record) =>
    record.action === "initial"
      ? record.action === editingKey
      : record.id === editingKey;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const edit = (record) => {
    form.setFieldsValue({
      name: null,
      note: null,
      sortNumber: searchHs1.length,
      ...record,
    });
    setEditingKey(record.action === "initial" ? record.action : record.id);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = searchHs1.slice(0);
      const index = newData.findIndex((item) =>
        item.id ? item.id === key : item.action === key
      );
      const item = newData[index];
      const payload = {
        id: item.id || null,
        name: item.name,
        note: item.note,
        ...row,
        onSuccess: () => {
          dispatch(searchHSC1());
          setEditingKey("");
        },
      };

      if (key === "initial") {
        dispatch(addNewHSC1(payload));
      } else {
        dispatch(updateHSC1(payload));
      }
    } catch (error) {}
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allowView = (value) =>
    Utils.checkPermitValue(value, permitList, permitKey.hrm_hoso);

  useEffect(() => {
    dispatch(searchHSC1());
  }, [dispatch]);

  const tableColumns = useMemo(
    () => [
      {
        title: "STT",
        align: "center",
        render: (_, __, index) => index + 1,
      },
      {
        title: "Tên hồ sơ",
        dataIndex: "name",
        editable: true,
      },
      {
        title: "Ghi chú",
        dataIndex: "note",
        editable: true,
      },
      {
        title: "Sắp xếp",
        dataIndex: "sortNumber",
        align: "center",
        editable: true,
      },
      {
        title: "Người tạo",
        align: "center",
        dataIndex: "createdName",
      },
      {
        title: "Thời gian tạo",
        align: "center",
        dataIndex: "createdAt",
        render: (date) => date && Utils.formatDate(date),
      },
      {
        title: "Trạng thái",
        align: "center",
        dataIndex: "status",
        render: (value, record) =>
          record.id && (
            <Tag color={value ? "green" : "red"}>
              {value ? "Đang sử dụng" : "Ngừng sử dụng"}
            </Tag>
          ),
      },
      {
        fixed: "right",
        align: "center",
        render: (_, record, index) => {
          const editable = isEditing(record);
          return editable ? (
            <>
              <Button
                onClick={() => save(record?.id || "initial")}
                className="mr-2"
                icon={<SaveOutlined />}
                shape="circle"
              />
              <Button
                onClick={() => setEditingKey("")}
                className="mr-2"
                icon={<CloseOutlined />}
                shape="circle"
              />
            </>
          ) : (
            <>
              <Button
                title={
                  record.action === "initial"
                    ? "Thêm thông tin"
                    : "Sửa thông tin"
                }
                disabled={
                  record.action === "initial"
                    ? !allowView(PermitValue.them)
                    : !allowView(PermitValue.sua)
                }
                onClick={() => edit(record)}
                className="mr-2"
                icon={
                  record.action === "initial" ? (
                    <PlusOutlined />
                  ) : (
                    <EditOutlined />
                  )
                }
                shape="circle"
              />
              {record.id && (
                <>
                  <Popconfirm
                    title={"Khóa/Mở khóa hồ sơ"}
                    placement="topLeft"
                    onConfirm={() => {
                      dispatch(
                        changeStateHSC1({
                          id: record.id,
                          onSuccess: () => {
                            dispatch(searchHSC1());
                          },
                        })
                      );
                    }}
                  >
                    <Button
                      title={"Khóa/Mở khóa hồ sơ"}
                      className="mr-2"
                      disabled={!allowView(PermitValue.khoa)}
                      icon={
                        record.status ? (
                          <UnlockOutlined type="primary" />
                        ) : (
                          <LockOutlined type="primary" />
                        )
                      }
                      shape="circle"
                    />
                  </Popconfirm>
                  <Popconfirm
                    title="Bạn có muốn xóa không?"
                    placement="topLeft"
                    onConfirm={() => {
                      dispatch(
                        deleteHSC1({
                          id: record.id,
                          onSuccess: () => {
                            dispatch(searchHSC1());
                          },
                        })
                      );
                    }}
                  >
                    <Button
                      title={"Xóa thông tin"}
                      disabled={!allowView(PermitValue.xoa)}
                      className="mr-2"
                      icon={<DeleteOutlined type="primary" />}
                      shape="circle"
                    />
                  </Popconfirm>
                </>
              )}
            </>
          );
        },
      },
    ],
    [allowView, dispatch, edit, isEditing, save]
  );

  const mergedColumns = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "type"
            ? "select"
            : col.dataIndex === "position"
            ? "number"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Card>
      <h3>HỒ SƠ CẤP 1</h3>
      <Spin tip="Đang tải..." spinning={loading}>
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={mergedColumns}
            dataSource={searchHs1}
            scroll={{ x: "max-content" }}
            rowKey={(item) => item?.id}
          />
        </Form>
      </Spin>
    </Card>
  );
};

export default HoSoCap1;
