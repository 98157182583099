import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { cloneDeep } from "lodash";
import chuyenMonCategoryService from "services/thietLapKhac/ChuyenMonCategoryService";

export const getDanhMucChuyenMon = createAsyncThunk(
  "chuyenMonCategory/getDanhMucChuyenMon",
  async (branchId, { rejectWithValue }) => {
    try {
      const response = await chuyenMonCategoryService.Get(branchId);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateDanhMucChuyenMon = createAsyncThunk(
  "chuyenMonCategory/updateDanhMucChuyenMon",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await chuyenMonCategoryService.Update(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createDanhMucChuyenMon = createAsyncThunk(
  "chuyenMonCategory/createDanhMucChuyenMon",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await chuyenMonCategoryService.Create(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const deleteDanhMucChuyenMon = createAsyncThunk(
  "chuyenMonCategory/deleteDanhMucChuyenMon",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await chuyenMonCategoryService.Delete(id);
      if (onSuccess) onSuccess(response);
      return id;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  danhMucChuyenMonList: [],
  danhMucChuyenMonListSetting: [],
  error: null,
};

const chuyenMonCategorySlice = createSlice({
  name: "chuyenMonCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDanhMucChuyenMon.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDanhMucChuyenMon.fulfilled, (state, action) => {
        state.loading = false;
        const payload = action.payload || [];
        const filteredPayload = payload.filter(
          (item) => item.IsActivated === true
        );
        state.danhMucChuyenMonList = filteredPayload;
        state.danhMucChuyenMonListSetting = [
          {
            action: "initial",
            isRequired: true,
          },
          ...payload,
        ];
      })
      .addCase(getDanhMucChuyenMon.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateDanhMucChuyenMon.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDanhMucChuyenMon.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateDanhMucChuyenMon.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createDanhMucChuyenMon.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDanhMucChuyenMon.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createDanhMucChuyenMon.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteDanhMucChuyenMon.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDanhMucChuyenMon.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteDanhMucChuyenMon.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default chuyenMonCategorySlice.reducer;
