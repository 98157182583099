import { CHUYEN_TIEN_NOI_BO_API } from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const ChuyenNoiBoService = {};

ChuyenNoiBoService.GetAllChuyenNoiBo = function (data) {
  return axiosInstance.post(
    `${CHUYEN_TIEN_NOI_BO_API}/get-all-chuyen-noi-bo`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

ChuyenNoiBoService.GetChuyenNoiBo = function (data) {
  return axiosInstance.get(
    `${CHUYEN_TIEN_NOI_BO_API}/get-chuyen-noi-bo?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

ChuyenNoiBoService.UpsertChuyenNoiBo = function (data) {
  return axiosInstance.put(
    `${CHUYEN_TIEN_NOI_BO_API}/up-sert-chuyen-noi-bo`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

ChuyenNoiBoService.DeleteChuyenNoiBo = function (data) {
  return axiosInstance.delete(
    `${CHUYEN_TIEN_NOI_BO_API}/xoa-chuyen-noi-bo?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

ChuyenNoiBoService.GetChuyenNoiBoChiTiet = function (data) {
  return axiosInstance.get(
    `${CHUYEN_TIEN_NOI_BO_API}/get-chuyen-noi-bo-chi-tiet?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

ChuyenNoiBoService.UpsertChuyenNoiBoChiTiet = function (data) {
  return axiosInstance.put(
    `${CHUYEN_TIEN_NOI_BO_API}/up-sert-chuyen-noi-bo-chi-tiet`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

ChuyenNoiBoService.DeleteChuyenNoiBoChiTiet = function (data) {
  return axiosInstance.delete(
    `${CHUYEN_TIEN_NOI_BO_API}/xoa-chuyen-noi-bo-chi-tiet?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

export default ChuyenNoiBoService;
