import axiosInstance from "auth/FetchInterceptor";
import { THUCHI_API } from "constants/ApiConstant";
const ThuChiService = {};

ThuChiService.getDSThuChi = function (data) {
  return axiosInstance.post(`${THUCHI_API}/thu-tien/search-grid`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

ThuChiService.LuuPhieuThuChi = function (data) {
  return axiosInstance.post(`${THUCHI_API}/thu-tien/luu-thu-chi`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

ThuChiService.getPhieuThuChi = function (maphieu) {
  return axiosInstance.get(
    `${THUCHI_API}/thu-tien/phieu-thu-chi?maphieu=${maphieu}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

ThuChiService.deleteThuChi = function (maphieu) {
  return axiosInstance.delete(
    `${THUCHI_API}/thu-tien/xoa-thu-chi?maphieu=${maphieu}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

ThuChiService.CreatePrinter = function (data) {
  return axiosInstance.post(`printer/create-print`, data, {
    //printer/create-print
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};

export default ThuChiService;
