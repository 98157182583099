import {
  CREATE_DINH_KHOAN_CATE_API,
  DELETE_DINH_KHOAN_CATE_API,
  GET_DINH_KHOAN_CATE_API,
  GET_DINH_KHOAN_CATE_BY_ID_API,
  GET_TREE_DINH_KHOAN_CATE_API,
  UPDATE_DINH_KHOAN_CATE_API,
  DANH_MUC_NGAM_DINH_API,
  DANH_MUC_QUY_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const dinhKhoanCategoryService = {};

dinhKhoanCategoryService.GetTree = function (data) {
  return axiosInstance.post(`${GET_TREE_DINH_KHOAN_CATE_API}`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};
dinhKhoanCategoryService.GetCate = function () {
  return axiosInstance.get(`${GET_DINH_KHOAN_CATE_API}`, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};
dinhKhoanCategoryService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_DINH_KHOAN_CATE_API}`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};
dinhKhoanCategoryService.Create = function (data) {
  return axiosInstance.post(`${CREATE_DINH_KHOAN_CATE_API}`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};
dinhKhoanCategoryService.Delete = function (data) {
  return axiosInstance.delete(`${DELETE_DINH_KHOAN_CATE_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};
dinhKhoanCategoryService.GetById = function (data) {
  return axiosInstance.get(`${GET_DINH_KHOAN_CATE_BY_ID_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

/// Danh mục ngầm định

dinhKhoanCategoryService.GetAllDanhMucNgamDinh = function (data) {
  return axiosInstance.post(
    `${DANH_MUC_NGAM_DINH_API}/get-all-danh-muc-ngam-dinh`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

dinhKhoanCategoryService.UpsertDanhMucNgamDinh = function (data) {
  return axiosInstance.put(
    `${DANH_MUC_NGAM_DINH_API}/upsert-danh-muc-ngam-dinh`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};
dinhKhoanCategoryService.XoaDanhMucNgamDinh = function (data) {
  return axiosInstance.delete(
    `${DANH_MUC_NGAM_DINH_API}/xoa-danh-muc-ngam-dinh?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

/// Danh mục Quy

dinhKhoanCategoryService.GetAllDanhMucQuy = function (data) {
  return axiosInstance.post(`${DANH_MUC_QUY_API}/get-all-danh-muc-quy`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

dinhKhoanCategoryService.UpsertDanhMucQuy = function (data) {
  return axiosInstance.put(`${DANH_MUC_QUY_API}/upsert-danh-muc-quy`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};
dinhKhoanCategoryService.XoaDanhMucQuy = function (data) {
  return axiosInstance.delete(
    `${DANH_MUC_QUY_API}/xoa-danh-muc-quy?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};
export default dinhKhoanCategoryService;
