import axiosInstance from "auth/FetchInterceptor";
import { CRM_EXPORT_API } from "constants/ApiConstant";
const CrmExportService = {};

CrmExportService.getByType = function (type) {
  return axiosInstance.get(`${CRM_EXPORT_API}/get-by-type?type=${type}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

CrmExportService.exportDSKhachHang = function (data) {
  return axiosInstance.post(`${CRM_EXPORT_API}/khach-hang`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
export default CrmExportService;
