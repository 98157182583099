import axiosInstance from "auth/FetchInterceptor";
import { TRACUU_API } from "constants/ApiConstant";
const TraCuuService = {};

TraCuuService.getTraCuuTon = function (data) {
  return axiosInstance.post(`${TRACUU_API}/tra-cuu-ton`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
TraCuuService.getTraCuuCongNo = function (data) {
  return axiosInstance.post(`${TRACUU_API}/tra-cuu-cong-no`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
TraCuuService.getXemThongTinHanghoa = function (data) {
  return axiosInstance.post(`${TRACUU_API}/xem-thong-tin-hang-hoa`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
TraCuuService.getDanhSachDaXuatHanghoa = function (data) {
  return axiosInstance.post(`${TRACUU_API}/danh-sach-da-xuat-hang-hoa`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
TraCuuService.capNhatTonHanghoa = function (data) {
  return axiosInstance.post(`${TRACUU_API}/cap-nhat-ton-hang-hoa`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
TraCuuService.getBCDoanhThu = function (data) {
  return axiosInstance.post(`${TRACUU_API}/bao-cao-doanh-thu`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

export default TraCuuService;
