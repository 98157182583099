import axiosInstance from "auth/FetchInterceptor";
import { AMBULANCE_API } from "constants/ApiConstant";
const AmbulanceService = {};

AmbulanceService.searchGrid = function (data) {
  return axiosInstance.post(`${AMBULANCE_API}/search-grid`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

AmbulanceService.getAmbulanceById = function (id) {
  return axiosInstance.get(`${AMBULANCE_API}/get-by-id?id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

export default AmbulanceService;
