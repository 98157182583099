import axiosInstance from "auth/FetchInterceptor";
import { NOTIFICATION_API } from "constants/ApiConstant";
const NotificationService = {};

NotificationService.getAllNotification = function (data) {
  return axiosInstance.post(`${NOTIFICATION_API}/search-grid`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

NotificationService.getNotificationById = function (id) {
  return axiosInstance.get(`${NOTIFICATION_API}/get-by-id?id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

NotificationService.create = function (data) {
  return axiosInstance.post(NOTIFICATION_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

NotificationService.update = function (data) {
  return axiosInstance.put(NOTIFICATION_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

NotificationService.delete = function (id) {
  return axiosInstance.delete(`${NOTIFICATION_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

export default NotificationService;
