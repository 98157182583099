import axiosInstance from "auth/FetchInterceptor";
import { CDHA_VIEW_API, XETNGHIEM_VIEW_API } from "constants/ApiConstant";
const CdhaService = {};

CdhaService.getCdhaById = function (id) {
  return axiosInstance.get(`${CDHA_VIEW_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

CdhaService.getXetNghiemByCode = function (data) {
  return axiosInstance.get(`${XETNGHIEM_VIEW_API}?code=${data}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
export default CdhaService;
