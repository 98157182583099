import axiosInstance from "auth/FetchInterceptor";
import { KE_HOACH_MUA_HANG_API, DANHMUC_API } from "constants/ApiConstant";
const KeHoachMuaHangService = {};

// start kehoachmuahang

KeHoachMuaHangService.getbyid = function (id) {
  return axiosInstance.get(`${KE_HOACH_MUA_HANG_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};
KeHoachMuaHangService.create = function (data) {
  return axiosInstance.post(`${KE_HOACH_MUA_HANG_API}`, data, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};
KeHoachMuaHangService.getall = function (data) {
  return axiosInstance.post(`${KE_HOACH_MUA_HANG_API}/get-all`, data, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};
KeHoachMuaHangService.GetAllKeHoach = function (id) {
  return axiosInstance.get(`${KE_HOACH_MUA_HANG_API}/get-all-ke-hoach`, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};
KeHoachMuaHangService.update = function (data) {
  return axiosInstance.put(`${KE_HOACH_MUA_HANG_API}`, data, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};
KeHoachMuaHangService.delete = function (id) {
  return axiosInstance.delete(`${KE_HOACH_MUA_HANG_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};

// gửi yêu cầu xử lý đơn

// end kehoachmuahang

// start chitietkehoachmuahang
KeHoachMuaHangService.createChiTiet = function (data) {
  return axiosInstance.post(`${KE_HOACH_MUA_HANG_API}/chi-tiet`, data, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};

KeHoachMuaHangService.getDsChiTietByID = function (id) {
  return axiosInstance.get(`${KE_HOACH_MUA_HANG_API}/chi-tiet?id=${id}`, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};

KeHoachMuaHangService.deleteDeXuatChiTiet = function (id) {
  return axiosInstance.delete(`${KE_HOACH_MUA_HANG_API}/chi-tiet?id=${id}`, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};

KeHoachMuaHangService.getDmHangHoa = function (data) {
  return axiosInstance.post(`${DANHMUC_API}/get-dmhanghoa`, data, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};

KeHoachMuaHangService.getGiaNhapGanNhat = function (data) {
  return axiosInstance.post(
    `${KE_HOACH_MUA_HANG_API}/chi-tiet/gia-nhap-gan-nhat`,
    data,
    {
      baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
    }
  );
};

KeHoachMuaHangService.xacNhanChiTiet = function (data) {
  return axiosInstance.put(`${KE_HOACH_MUA_HANG_API}/chi-tiet/xac-nhan`, data, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};

// end chitietkehoachmuahang

export default KeHoachMuaHangService;
