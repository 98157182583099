import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Result, Spin, Form, Typography, Modal } from "antd";
import SlipButton from "components/SlipButton";

import { PlusOutlined } from "@ant-design/icons";
import {
  getByObjectId,
  updateAttachment,
  deleteAttachment,
} from "store/slices/common/attachmentSlide";
import MediTable from "components/Custom";
import EditableCell from "components/EditableCell";
import ActionButtons from "components/ActionButtons";
import ModalUpload from "./ModalUpload.js";
import { MediActionDownload } from "components/Custom";
import Utils from "utils";
import { isMobile } from "react-device-detect";
import { isImageURL } from "utils/helper";
const { confirm } = Modal;
const { Search } = Input;
const CommonAttachment = ({ objectId, objectType, selectedRow }) => {
  // console.log(selectedRow, "selectedRow")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [visibleUploadModel, setVisibleUploadModal] = useState(false);
  const [listAttachment, setListAttachment] = useState([]);
  const [originalAttachmentList, setOriginalAttachmentList] = useState([]); // Thêm state này
  const { loading, attachmentList } = useSelector((state) => state.attachment);
  const { profile } = useSelector((state) => state.auth);
  const [editingKey, setEditingKey] = useState("");
  const [defContent, setDefContent] = useState("");
  // console.log(objectId, "objectIdobjectId");
  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectId]);
  useEffect(() => {
    setOriginalAttachmentList([...attachmentList]);
    setListAttachment([...attachmentList]);
  }, [attachmentList]);
  const reload = () => {
    if (objectId) {
      const payload = {
        objectId: objectId,
        objectType: objectType,
      };
      dispatch(getByObjectId(payload));
    }
  };

  const handleClickAdd = () => {
    setDefContent("");
    setVisibleUploadModal(true);
  };
  const handleClickOfficialDocs = () => {
    confirm({
      icon: <></>,
      centered: true,
      content: (
        <div style={{ fontWeight: 500 }}>
          Văn bản chính thức chỉ được thêm bởi người lập phiếu, không được sửa,
          xóa <br />
          Xác nhận thêm ?
        </div>
      ),
      onOk() {
        setDefContent("Văn bản chính thức");
        setVisibleUploadModal(true);
      },
    });
  };
  const onhandleSearch = (value) => {
    const filteredList = originalAttachmentList.filter(
      (item) =>
        item.description.toLowerCase().includes(value.toLowerCase()) ||
        item.fileName.toLowerCase().includes(value.toLowerCase())
    );
    setListAttachment(filteredList);
  };

  const isEditing = useCallback(
    (record) =>
      record.action === "initial"
        ? record.action === editingKey
        : record.id === editingKey,
    [editingKey]
  );

  const edit = useCallback(
    (record) => {
      form.setFieldsValue({
        description: "aaaa",
        ...record,
      });
      setEditingKey(record.action === "initial" ? record.action : record.id);
      console.log("edit", record);
    },
    [form]
  );

  const cancel = useCallback(() => {
    setEditingKey("");
  }, []);

  const save = useCallback(
    async (key) => {
      try {
        const row = await form.validateFields();
        const newData = [...listAttachment];
        const index = newData.findIndex((item) =>
          item.id ? item.id === key : item.action === key
        );
        const item = newData[index];
        const payload = {
          id: item.id || null,
          description: row.description,
        };
        const data = {
          payload: payload,
          onSuccess: () => {
            reload();
            setEditingKey("");
          },
        };
        dispatch(updateAttachment(data));
      } catch (error) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listAttachment, dispatch, form]
  );
  const handleDelete = useCallback(
    (id) => {
      const data = {
        id: id,
        onSuccess: () => {
          reload();
        },
      };
      dispatch(deleteAttachment(data));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  const getHrefFromLink = (link) => {
    if (isImageURL(link)) return link;
    // return `https://docs.google.com/viewerng/viewer?url=${link}`;
    return `/documentviewer?url=${link}`;
  };
  const allowAddOfficialDocs = () => {
    let ret = false;
    if (
      (objectType === "VanBan" || objectType === "HopDongLaoDong") &&
      profile &&
      selectedRow &&
      (profile?.id === selectedRow?.createdUserById ||
        profile?.id === selectedRow?.createdById) &&
      handleDisableButton()
    )
      ret = true;
    return ret;
  };
  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      align: "center",
      fixed: !isMobile ? "left" : false,
      render: (_, __, index) => index + 1,
      width: 50,
    },
    {
      title: "Nội dung",
      dataIndex: "description",
      align: "left",
      editable: true,
      key: "description",
      // width: 00,
      render: (text, record) => (
        <div
          style={{
            cursor: "pointer",
            // fontWeight: "bold",
            display: "inline-flex",
            textDecoration: "none",
            color: "initial",
          }}
          className="d-flex"
          onClick={() => {
            if (record?.officialUrl && record?.fileUrl) {
              const link = document.createElement("a");
              const linkUrl = record?.fileUrl.replace("http:", "https:");
              link.href = linkUrl;
              link.click();
            }
          }}
          onMouseEnter={(e) => {
            if (record?.officialUrl && record?.fileUrl) {
              e.target.style.textDecoration = "underline";
              e.target.style.color = "blue";
            }
          }}
          onMouseLeave={(e) => {
            if (record?.officialUrl && record?.fileUrl) {
              e.target.style.textDecoration = "none";
              e.target.style.color = "initial";
            }
          }}
          title={record?.officialUrl ? "Tải file gốc" : ""}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Tên file",
      dataIndex: "fileName",
      align: "left",
      width: 220,
      render: (text, record) => (
        <a
          href={
            record.officialUrl
              ? getHrefFromLink(record.officialUrl)
              : getHrefFromLink(record.fileUrl)
          }
          target="_blank"
          style={{ display: "flex", alignContent: "center" }}
          rel="noreferrer"
        >
          {
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "8px",
              }}
            >
              {Utils.GetFileIcon(
                record.fileType,
                record.officialUrl ? true : false
              )}
            </div>
          }{" "}
          <div
            style={{
              width: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {text}
          </div>
        </a>
      ),
    },
    {
      title: "Người tải lên",
      dataIndex: "createdByName",
      width: 150,
      align: "left",
    },
    {
      title: "Ngày tải lên",
      dataIndex: "createdTime",
      width: 150,
      align: "center",
      render: (value) => Utils.formatDate(value),
    },
    {
      title: "Loại file",
      dataIndex: "fileType",
      align: "center",
      width: 75,
    },
    {
      title: "Dung lượng",
      dataIndex: "fileSizeKb",
      align: "center",
      width: 100,
      render: (value) => `${value} KB`,
    },
    {
      fixed: !isMobile ? "right" : false,
      align: "center",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <ActionButtons
            record={record}
            editable={editable}
            save={save}
            cancel={cancel}
            edit={edit}
            handleDelete={handleDelete}
            disabled={handleDisableButton()}
            extraButtons={() => {
              const url = record.officialUrl
                ? record.officialUrl
                : record.fileUrl;
              return (
                <Typography.Link
                  href={url?.replace("http:", "https:")}
                  target="_blank"
                >
                  <MediActionDownload
                    className="mr-2"
                    // shape=""
                  />
                </Typography.Link>
              );
            }}
          />
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        title: col.title,
        dataIndex: col.dataIndex,
        editing: isEditing(record),
      }),
    };
  });

  const onSubmitSuccessHandler = () => {
    // reload data
    reload();
    setVisibleUploadModal(false);
  };

  const handleDisableButton = useCallback(() => {
    if (selectedRow) {
      return (
        selectedRow?.totalSigned > 1 ||
        (selectedRow?.totalSigned !== null &&
          selectedRow?.totalSigns !== null &&
          selectedRow?.totalSigned === selectedRow?.totalSigns)
      );
    } else {
      return false;
    }
  }, [selectedRow]);
  return (
    <>
      {objectId ? (
        <Spin tip="Đang tải..." spinning={loading}>
          <div className="custom-formdetail">
            <div className="flex-space">
              <div
                style={{ display: "flex", alignItems: "center", width: 200 }}
              >
                <Search
                  placeholder="Tìm tên file, nội dung"
                  style={{ width: "100%" }}
                  onSearch={onhandleSearch}
                />
              </div>
              <div>
                {allowAddOfficialDocs() && (
                  <SlipButton
                    type="primary"
                    size="middle"
                    text="VB Chính thức"
                    icon={PlusOutlined}
                    onClick={handleClickOfficialDocs}
                    style={{ marginRight: "4px" }}
                  ></SlipButton>
                )}
                <SlipButton
                  type="primary"
                  size="middle"
                  text="Thêm"
                  icon={PlusOutlined}
                  onClick={handleClickAdd}
                  disabled={handleDisableButton()}
                ></SlipButton>
              </div>
            </div>
            <ModalUpload
              objectId={objectId}
              objectType={objectType}
              isVisibleModal={visibleUploadModel}
              onCancelModal={() => {
                setVisibleUploadModal(false);
              }}
              onSubmitSuccess={onSubmitSuccessHandler}
              defContent={defContent}
            />
            <Form form={form} component={false}>
              <MediTable
                dataSource={listAttachment}
                tableColumns={mergedColumns}
                // onRenderFooter={onRenderFooter}
                rowKeyField={(item) => item.id}
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
              />
            </Form>
          </div>
        </Spin>
      ) : (
        <Result
          status="warning"
          title="Xin vui lòng tạo mới phiếu trước khi thao tác."
        />
      )}
    </>
  );
};

export default CommonAttachment;
