import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CrmExportService from "services/crm/CrmExportService";
import { cloneDeep } from "lodash";
export const getByType = createAsyncThunk(
  "crmexport/getByType",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CrmExportService.getByType(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const exportDSKhachHang = createAsyncThunk(
  "crmexport/exportDSKhachHang",
  async (data, { rejectWithValue }) => {
    try {
     
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      console.log('exportDSKhachHang', data, payload);
      const response = await CrmExportService.exportDSKhachHang(payload);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);


const initialState = {
  loading: false,
  transactionList: [],
};

export const crmExportSlide = createSlice({
  name: "crmexport",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getByType.pending, (state) => {
        state.loading = true;
      })
      .addCase(getByType.fulfilled, (state, action) => {
        state.loading = false;
        state.transactionList = action.payload;
      })
      .addCase(getByType.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(exportDSKhachHang.pending, (state) => {
        state.loading = true;
      })
      .addCase(exportDSKhachHang.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(exportDSKhachHang.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = crmExportSlide.actions;

export default crmExportSlide.reducer;
