import { Button, Input, Popconfirm, Table } from "antd";

import React, { useMemo, useState } from "react";
import { styled } from "styled-components";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
// import Utils from "utils";
// import { permitKey, PermitValue } from "constants";
import {
  createDmkk,
  setAllDmkkList,
  updateDmkk,
  deleteDmkk,
} from "store/slices/sale/danhmucSlice";

import { initDmkk } from "views/app-views/sale/category/Dmkk";
const formater = new Intl.NumberFormat("vi-VN");
// formatter and parser input number
export const formatterNumber = (val) => {
  if (!val) return 0;
  return formater.format(val);
};

// export const parserNumber = (val) => {
//   if (!val) return 0;
//   return Number.parseFloat(
//     val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")
//   ).toFixed(5);
// };
const TableDmkkList = ({
  allDmKkList,
  reloadData,
  permitList = [],
  maLoaiKk,
}) => {
  const [editingKey, setEditingKey] = useState("");
  //const isEditing = (record) => record.key === editingKey;

  const dispatch = useDispatch();

  // const allowLock = Utils.checkPermitValue(
  //   PermitValue.khoa,
  //   permitList,
  //   permitKey.crm_hospital
  // );
  // const allowEdit = Utils.checkPermitValue(
  //   PermitValue.sua,
  //   permitList,
  //   permitKey.crm_hospital
  // );
  // const allowDelete = Utils.checkPermitValue(
  //   PermitValue.xoa,
  //   permitList,
  //   permitKey.crm_hospital
  // );

  const onChangeText = (type, record) => (e) => {
    const newData = allDmKkList.slice(0);
    const value = e?.target?.value ?? e;
    const index1 = newData.findIndex((item) => item.makk === record.makk);
    newData[index1] = { ...newData[index1], [type]: value };
    dispatch(setAllDmkkList(newData));
  };
  const onClickAction = (record, index) => {
    const newData = allDmKkList.slice(0);

    const payload = {
      makk: record.makk,
      tenkk: record.tenkk,
      viettat: record.viettat,
      sapxep: record.sapxep,
      diachi: record.diachi,
      nguoilienhe: record.nguoilienhe,
      dienthoai: record.dienthoai,
      maloaikk: maLoaiKk,
      mathietlapkk: 0,
      khonhap: false,
      onSuccess: () => {
        reloadData();
      },
    };
    if (record.action === initDmkk.action) {
      dispatch(createDmkk(payload));
    } else {
      if (record.makk !== null && record.action !== "update") {
        const index1 = newData.findIndex((item) => item.makk === record.makk);
        newData[index1] = { ...newData[index1], action: "update" };
        dispatch(setAllDmkkList(newData));
      } else {
        dispatch(updateDmkk(payload));
      }
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        title: "STT",
        width: 50,
        align: "center",
        render: (_, __, index) => index + 1,
      },
      {
        title: "Mã số",
        width: 90,
        dataIndex: "makk",
      },
      {
        title: "Tên",
        width: 180,
        dataIndex: "tenkk",
        render: (_, record, index) =>
          record.action ? (
            <Input
              style={{ width: "100%" }}
              value={record.tenkk}
              placeholder="Nhập tên..."
              onChange={onChangeText("tenkk", record)}
            />
          ) : (
            record.tenkk
          ),
      },
      {
        title: "Địa chỉ",
        width: 250,
        dataIndex: "diachi",
        render: (_, record, index) =>
          record.action ? (
            <Input
              style={{ width: "100%" }}
              value={record.diachi}
              placeholder="Địa chỉ..."
              onChange={onChangeText("diachi", record)}
            />
          ) : (
            record.diachi
          ),
      },
      {
        title: "Điện thoại",
        width: 150,
        dataIndex: "dienthoai",
        render: (_, record, index) =>
          record.action ? (
            <Input
              style={{ width: "100%" }}
              value={record.dienthoai}
              placeholder="SĐT..."
              onChange={onChangeText("dienthoai", record)}
            />
          ) : (
            record.dienthoai
          ),
      },
      {
        title: "Người liên hệ",
        width: 180,
        dataIndex: "nguoilienhe",
        render: (_, record, index) =>
          record.action ? (
            <Input
              style={{ width: "100%" }}
              value={record.nguoilienhe}
              placeholder="Người liên hệ..."
              onChange={onChangeText("nguoilienhe", record)}
            />
          ) : (
            record.nguoilienhe
          ),
      },
      {
        fixed: "right",
        width: 90,
        align: "center",
        dataIndex: "action",
        render: (_, record, index) => (
          <>
            {record.action === "initial" ? (
              <Button
                //disabled={!record.tenhh || !allowView(PermitValue.them)}
                title={"Thêm thông tin"}
                onClick={() => onClickAction(record, index)}
                className="mr-2"
                icon={<PlusOutlined />}
                shape="circle"
              />
            ) : (
              <>
                <Button
                  disabled={editingKey !== "" && editingKey !== record.makk}
                  title={"Sửa thông tin"}
                  onClick={() => {
                    setEditingKey(editingKey !== "" ? "" : record.makk);
                    onClickAction(record, index);
                  }}
                  className="mr-2"
                  icon={
                    record.action === "update" ? (
                      <SaveOutlined />
                    ) : (
                      <EditOutlined />
                    )
                  }
                  shape="circle"
                />
                <Popconfirm
                  title="Bạn có muốn xóa không?"
                  placement="topLeft"
                  onConfirm={() => {
                    dispatch(
                      deleteDmkk({
                        id: record.makk,
                        onSuccess: (r) => {
                          reloadData();
                        },
                      })
                    );
                  }}
                >
                  <Button
                    disabled={editingKey !== ""}
                    title={"Xóa thông tin"}
                    //disabled={!allowView(PermitValue.xoa)}
                    className="mr-2"
                    icon={<DeleteOutlined type="primary" />}
                    shape="circle"
                  />
                </Popconfirm>
              </>
            )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allDmKkList]
  );

  return (
    <CustomTable
      columns={tableColumns}
      dataSource={allDmKkList}
      rowKey={(item) => item?.makk}
      bordered
      style={{ padding: "0px" }}
      fixed={true}
      scroll={{ x: "max-content" }}
    />
  );
};

const CustomTable = styled(Table)`
  .ant-table-tbody > tr > td {
    padding: 2px;
  }
`;

export default TableDmkkList;
