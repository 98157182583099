import axiosInstance from "auth/FetchInterceptor";
import { POSTS_API, SEARCH_POSTS_API } from "constants/ApiConstant";
const PostsService = {};

PostsService.searchGridPosts = function (data) {
  return axiosInstance.post(SEARCH_POSTS_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

PostsService.postsById = function (id) {
  return axiosInstance.get(`${POSTS_API}?Id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

PostsService.createPosts = function (data) {
  return axiosInstance.post(POSTS_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

PostsService.updatePosts = function (data) {
  return axiosInstance.put(POSTS_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

PostsService.deletePosts = function (id) {
  return axiosInstance.delete(`${POSTS_API}?Id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

export default PostsService;
