import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ReportService from "services/ReportService.js";
import { cloneDeep } from "lodash";

export const getFeedbackFromCustomer = createAsyncThunk(
  "report/getFeedbackFromCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ReportService.getFeedBackFromCustomer(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const searchDanhGiaChatLuong = createAsyncThunk(
  "report/searchDanhGiaChatLuong",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ReportService.searchDGCL(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const getFeedbackFromCustomerExport = createAsyncThunk(
  "report/getFeedbackFromCustomerExport",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ReportService.getFeedBackFromCustomerExport(
        payload
      );
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getAllDoctorReport = createAsyncThunk(
  "report/getAllDoctorReport",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ReportService.getAllDoctor();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const getReportThisHospital = createAsyncThunk(
  "report/getReportThisHospital",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ReportService.getReportThisHospital(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getReportAllHospital = createAsyncThunk(
  "report/getReportAllHospital",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ReportService.getReportAllHospital(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const getReportKcbAllHospital = createAsyncThunk(
  "report/getReportKcbAllHospital",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ReportService.getReportKcbAllHospital(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const getReportKcbHospital = createAsyncThunk(
  "report/getReportKcbHospital",
  async (data, { rejectWithValue }) => {
    try {
      const idSS = '82a6d16c-94de-45b0-9d90-eb297c34565e';
      const idHl = 'f11435db-aa62-4770-823a-628771e55c12';
      let factor = 1;
      if (data.hospitalId === idSS) factor = 6;
      else if (data.hospitalId === idHl) factor = 4;
      const response = await ReportService.getReportKcbHospital(data);
      if (false) {
        console.log('response.data 1', response.data);
        const doanhThu = response.data.doanhThuKCBData.map(t => {
          if(t.name === 'Doanh thu Dịch vụ')
            return {...t, data: t.data.map(x => x * factor)}
          else if (t.name === 'Tổng doanh thu') {
            const dtbaoHiem = response.data.doanhThuKCBData.find(item => item.name === 'Doanh thu BHYT');
            const dtDv = response.data.doanhThuKCBData.find(item => item.name === 'Doanh thu Dịch vụ');
            console.log('dtbaoHiem', dtbaoHiem);
            const data = t.data.map((x, index) => {
              return dtbaoHiem.data[index] + dtDv.data[index]* factor
            });
            return {...t, data: data}
          }
          else if (t.name === 'Lượt Dịch vụ') {
            return {...t, data: t.data.map(x => x * factor)}
          }
          else if (t.name === 'Lượt KCB') {
            const dtbaoHiem = response.data.doanhThuKCBData.find(item => item.name === 'Lượt BHYT');
            const dtDv = response.data.doanhThuKCBData.find(item => item.name === 'Lượt Dịch vụ');
            console.log('dtbaoHiem', dtbaoHiem);
            const data = t.data.map((x, index) => {
              return dtbaoHiem.data[index] + dtDv.data[index]* factor
            });
            return {...t, data: data}
          }
          return {...t};
        });
        const hoatDongKCBData = response.data.hoatDongKCBData.map(t => {
          return {...t, data: t.data.map(x => x * factor)}
        });
        const t =  {...response.data, doanhThuKCBData: doanhThu, hoatDongKCBData: hoatDongKCBData}
        console.log('response.data', t);
        
        return t;
      } else {
        return response.data; 
      }

    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
const initialState = {
  loading: false,
  customerList: [],
  danhGiaList: [],
  tieuChiList: [],
  viTriList: [],
  reportHospital: {},
  reportAllHospital: {},
  reportKcbAllHospital: {},
  reportKcbHospital: {},
  doctorList: [],
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeedbackFromCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFeedbackFromCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.customerList = action.payload;
      })
      .addCase(getFeedbackFromCustomer.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getFeedbackFromCustomerExport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFeedbackFromCustomerExport.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getFeedbackFromCustomerExport.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getReportAllHospital.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReportAllHospital.fulfilled, (state, action) => {
        state.loading = false;
        state.reportAllHospital = action.payload;
      })
      .addCase(getReportAllHospital.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getReportThisHospital.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReportThisHospital.fulfilled, (state, action) => {
        state.loading = false;
        state.reportHospital = action.payload;
      })
      .addCase(getReportThisHospital.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getReportKcbAllHospital.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReportKcbAllHospital.fulfilled, (state, action) => {
        state.loading = false;
        state.reportKcbAllHospital = action.payload;
      })
      .addCase(getReportKcbAllHospital.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getReportKcbHospital.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReportKcbHospital.fulfilled, (state, action) => {
        state.loading = false;
        state.reportKcbHospital = action.payload;
      })
      .addCase(getReportKcbHospital.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(searchDanhGiaChatLuong.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchDanhGiaChatLuong.fulfilled, (state, action) => {
        state.loading = false;
        state.danhGiaList = action.payload.data;
        state.tieuChiList = action.payload.tieuChiList;
        state.viTriList = action.payload.viTriList;
      })
      .addCase(searchDanhGiaChatLuong.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllDoctorReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDoctorReport.fulfilled, (state, action) => {
        state.loading = false;
        state.doctorList = action.payload;
      })
      .addCase(getAllDoctorReport.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = reportSlice.actions;

export default reportSlice.reducer;
