import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { cloneDeep } from "lodash";
import hocVanCategoryService from "services/thietLapKhac/HocVanCategoryService";

export const getDanhMucHocVan = createAsyncThunk(
  "hocVanCategory/getDanhMucHocVan",
  async (branchId, { rejectWithValue }) => {
    try {
      const response = await hocVanCategoryService.Get(branchId);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateDanhMucHocVan = createAsyncThunk(
  "hocVanCategory/updateDanhMucHocVan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await hocVanCategoryService.Update(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createDanhMucHocVan = createAsyncThunk(
  "hocVanCategory/createDanhMucHocVan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await hocVanCategoryService.Create(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const deleteDanhMucHocVan = createAsyncThunk(
  "hocVanCategory/deleteDanhMucHocVan",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await hocVanCategoryService.Delete(id);
      if (onSuccess) onSuccess(response);
      return id;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  danhMucHocVanList: [],
  danhMucHocVanListSetting: [],
  error: null,
};

const hocVanCategorySlice = createSlice({
  name: "hocVanCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDanhMucHocVan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDanhMucHocVan.fulfilled, (state, action) => {
        state.loading = false;
        const payload = action.payload || [];
        const filteredPayload = payload.filter(
          (item) => item.IsActivated === true
        );
        state.danhMucHocVanList = filteredPayload;
        state.danhMucHocVanListSetting = [
          {
            action: "initial",
            isRequired: true,
          },
          ...payload,
        ];
      })
      .addCase(getDanhMucHocVan.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateDanhMucHocVan.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDanhMucHocVan.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateDanhMucHocVan.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createDanhMucHocVan.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDanhMucHocVan.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createDanhMucHocVan.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteDanhMucHocVan.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDanhMucHocVan.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteDanhMucHocVan.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default hocVanCategorySlice.reducer;
