import axiosInstance from "auth/FetchInterceptor";
import { HOSPITAL_MANAGER_API } from "constants/ApiConstant";
const HospitalManagerService = {};

HospitalManagerService.searchGrid = function (data) {
  return axiosInstance.post(`${HOSPITAL_MANAGER_API}/search-grid`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

HospitalManagerService.getHosiptalById = function (id) {
  return axiosInstance.get(`${HOSPITAL_MANAGER_API}/get-by-id?id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

HospitalManagerService.create = function (data) {
  return axiosInstance.post(HOSPITAL_MANAGER_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

HospitalManagerService.update = function (data) {
  return axiosInstance.put(HOSPITAL_MANAGER_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

HospitalManagerService.delete = function (id) {
  return axiosInstance.delete(`${HOSPITAL_MANAGER_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

HospitalManagerService.changeStateHosiptal = function (data) {
  return axiosInstance.put(`${HOSPITAL_MANAGER_API}/change-state`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

export default HospitalManagerService;
