import axiosInstance from "auth/FetchInterceptor";
import {
  DEPARTMENT_API,
  DEPARTMENT_SEARCH_GRID_API,
  DEPARTMENT_HOSPITALS_API,
} from "constants/ApiConstant";
const DepartmentService = {};

DepartmentService.searchGrid = function (data) {
  return axiosInstance.post(DEPARTMENT_SEARCH_GRID_API, data);
};
DepartmentService.departmentById = function (id) {
  return axiosInstance.get(`${DEPARTMENT_API}?id=${id}`, null);
};
DepartmentService.create = function (data) {
  return axiosInstance.post(DEPARTMENT_API, data);
};
DepartmentService.update = function (data) {
  return axiosInstance.put(DEPARTMENT_API, data);
};
DepartmentService.delete = function (id) {
  return axiosInstance.delete(`${DEPARTMENT_API}?id=${id}`, null);
};
DepartmentService.getAllHospital = function () {
  return axiosInstance.get(DEPARTMENT_HOSPITALS_API, null);
};
export default DepartmentService;
