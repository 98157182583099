import {
  GET_TREE_THU_CHI_CATE_API,
  GET_THU_CHI_CATE_API,
  UPDATE_THU_CHI_CATE_API,
  CREATE_THU_CHI_CATE_API,
  DELETE_THU_CHI_CATE_API,
  GET_THU_CHI_CATE_BY_ID_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const thuChiCategoryService = {};

thuChiCategoryService.GetTree = function (data) {
  return axiosInstance.post(`${GET_TREE_THU_CHI_CATE_API}`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};
thuChiCategoryService.GetCate = function (data) {
  return axiosInstance.get(`${GET_THU_CHI_CATE_API}?type=${data}`, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};
thuChiCategoryService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_THU_CHI_CATE_API}`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};
thuChiCategoryService.Create = function (data) {
  return axiosInstance.post(`${CREATE_THU_CHI_CATE_API}`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};
thuChiCategoryService.Delete = function (data) {
  return axiosInstance.delete(`${DELETE_THU_CHI_CATE_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};
thuChiCategoryService.GetById = function (data) {
  return axiosInstance.get(`${GET_THU_CHI_CATE_BY_ID_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};
export default thuChiCategoryService;
