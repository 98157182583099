import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import DeNghiChiTienService from "services/Finance/DeNghiChiTienService";

export const GetAllDeNghiChiTien = createAsyncThunk(
  "dinhKhoan/GetAllDeNghiChiTien",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DeNghiChiTienService.GetAllDeNghiChiTien(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetDeNghiChiTien = createAsyncThunk(
  "dinhKhoan/GetDeNghiChiTien",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DeNghiChiTienService.GetDeNghiChiTien(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertDeNghiChiTien = createAsyncThunk(
  "dinhKhoan/UpsertDeNghiChiTien",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeNghiChiTienService.UpsertDeNghiChiTien(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeleteDeNghiChiTien = createAsyncThunk(
  "dinhKhoan/DeleteDeNghiChiTien",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeNghiChiTienService.DeleteDeNghiChiTien(
        payload.id
      );
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const GetDeNghiChiTienChiTiet = createAsyncThunk(
  "dinhKhoan/GetDeNghiChiTienChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DeNghiChiTienService.GetDeNghiChiTienChiTiet(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetChiDeNghiChiTienChiTiet = createAsyncThunk(
  "dinhKhoan/GetChiDeNghiChiTienChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DeNghiChiTienService.GetChiDeNghiChiTienChiTiet(
        data
      );
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertDeNghiChiTienChiTiet = createAsyncThunk(
  "dinhKhoan/UpsertDeNghiChiTienChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeNghiChiTienService.UpsertDeNghiChiTienChiTiet(
        data
      );
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeleteDeNghiChiTienChiTiet = createAsyncThunk(
  "dinhKhoan/DeleteDeNghiChiTienChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeNghiChiTienService.DeleteDeNghiChiTienChiTiet(
        payload.id
      );
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  DeNghiChiList: [],
  DeNghiChiDetail: {},
  DeNghiChiChiTietList: [],
  ChiDeNghiChiChiTietList: [],
};

const deNghiChiSlice = createSlice({
  name: "deNghiChi",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //GetChiDeNghiChiTienChiTiet
      .addCase(GetChiDeNghiChiTienChiTiet.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetChiDeNghiChiTienChiTiet.fulfilled, (state, action) => {
        state.loading = false;
        state.ChiDeNghiChiChiTietList = action.payload;
      })
      .addCase(GetChiDeNghiChiTienChiTiet.rejected, (state) => {
        state.loading = false;
      })
      //GetDeNghiChiTienChiTiet
      .addCase(GetDeNghiChiTienChiTiet.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetDeNghiChiTienChiTiet.fulfilled, (state, action) => {
        state.loading = false;
        state.DeNghiChiChiTietList = action.payload;
      })
      .addCase(GetDeNghiChiTienChiTiet.rejected, (state) => {
        state.loading = false;
      })
      //GetDeNghiChiTien
      .addCase(GetDeNghiChiTien.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetDeNghiChiTien.fulfilled, (state, action) => {
        state.loading = false;
        state.DeNghiChiDetail = action.payload;
      })
      .addCase(GetDeNghiChiTien.rejected, (state) => {
        state.loading = false;
      })
      //GetAllDeNghiChiTien
      .addCase(GetAllDeNghiChiTien.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllDeNghiChiTien.fulfilled, (state, action) => {
        state.loading = false;
        state.DeNghiChiList = action.payload;
      })
      .addCase(GetAllDeNghiChiTien.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { showLoading, setHisInfo } = deNghiChiSlice.actions;

export default deNghiChiSlice.reducer;
