import {
  GET_REGULATION_MANAGE_API,
  GET_REGULATION_MANAGE_BY_ID_API,
  UPDATE_REGULATION_MANAGE_API,
  CREATE_REGULATION_MANAGE_API,
  DELETE_REGULATION_MANAGE_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const regulationService = {};


regulationService.GetManage = function (data) {
  return axiosInstance.post(`${GET_REGULATION_MANAGE_API}`, data, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
regulationService.GetManageById = function (data) {
  return axiosInstance.get(`${GET_REGULATION_MANAGE_BY_ID_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
regulationService.UpdateManage = function (data) {
  return axiosInstance.put(`${UPDATE_REGULATION_MANAGE_API}`, data, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
regulationService.CreateManage = function (data) {
  return axiosInstance.post(`${CREATE_REGULATION_MANAGE_API}`, data, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
regulationService.DeleteManage = function (data) {
  return axiosInstance.delete(`${DELETE_REGULATION_MANAGE_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};

export default regulationService;
