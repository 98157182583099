import { format, getTime, formatDistanceToNow } from 'date-fns';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}
export function fDateFormat(date, formatString) {
  return format(new Date(date), formatString);
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}
export function formatDateGMTZero(
  dateValue,
  stringFormat = 'YYYY-MM-DD HH:mm:ss',
  withoutTimezone = false,
) {
  if (!dateValue) {
    // eslint-disable-next-line no-param-reassign
    dateValue = dayjs();
  }

  if(withoutTimezone) {
    return dayjs(dateValue).utc().format(stringFormat);
  }

  if (!dayjs(dateValue).isValid) return '';

  return dayjs(dateValue).format(stringFormat);
}
export function resetLocalTimezone(date) {
  if (!date) return date;
  const tzOffset = (new Date()).getTimezoneOffset()
  const dt = dayjs(date).utcOffset(tzOffset).utc(false)
  return dt;
}
