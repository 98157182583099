import React from "react"

const EditSvg = (props) => (
    <span className="anticon">
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_349_1323)">
                <path d="M0.666672 15.8334H15.3333" stroke="#8D8D8D" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.3333 1.16669L14 3.83335L6.00002 11.8334L2.00002 13.1667L3.33335 9.16669L11.3333 1.16669Z" stroke="#8D8D8D" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_349_1323">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    </span>
)
export default EditSvg