import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dinhKhoanCategoryService from "services/Finance/DinhKhoanCategoryService";
import { cloneDeep } from "lodash";

export const getCateDinhKhoanTree = createAsyncThunk(
  "dinhKhoanCategory/getCateDinhKhoanTree",
  async (data, { rejectWithValue }) => {
    try {
      const response = await dinhKhoanCategoryService.GetTree(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getCateDinhKhoan = createAsyncThunk(
  "dinhKhoanCategory/getCateDinhKhoan",
  async (_, { rejectWithValue }) => {
    try {
      const response = await dinhKhoanCategoryService.GetCate();
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateCateDinhKhoan = createAsyncThunk(
  "dinhKhoanCategory/updateCateDinhKhoan",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await dinhKhoanCategoryService.Update(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createCateDinhKhoan = createAsyncThunk(
  "dinhKhoanCategory/createCateDinhKhoan",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await dinhKhoanCategoryService.Create(data);
      if (onSuccess) onSuccess(response.data);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);

export const deleteCateDinhKhoan = createAsyncThunk(
  "dinhKhoanCategory/deleteCateDinhKhoan",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await dinhKhoanCategoryService.Delete(id);
      if (onSuccess) onSuccess(response);
      return id;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);
export const getCateDinhKhoanById = createAsyncThunk(
  "dinhKhoanCategory/getCateDinhKhoanById",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await dinhKhoanCategoryService.GetById(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

//danh muc ngam dinh

export const GetAllDanhMucNgamDinh = createAsyncThunk(
  "dinhKhoanCategory/GetAllDanhMucNgamDinh",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await dinhKhoanCategoryService.GetAllDanhMucNgamDinh(
        data
      );
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);
export const UpsertDanhMucNgamDinh = createAsyncThunk(
  "dinhKhoanCategory/UpsertDanhMucNgamDinh",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await dinhKhoanCategoryService.UpsertDanhMucNgamDinh(
        data
      );
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);
export const XoaDanhMucNgamDinh = createAsyncThunk(
  "dinhKhoanCategory/XoaDanhMucNgamDinh",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await dinhKhoanCategoryService.XoaDanhMucNgamDinh(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);
//danh muc quy

export const GetAllDanhMucQuy = createAsyncThunk(
  "dinhKhoanCategory/GetAllDanhMucQuy",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await dinhKhoanCategoryService.GetAllDanhMucQuy(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);
export const UpsertDanhMucQuy = createAsyncThunk(
  "dinhKhoanCategory/UpsertDanhMucQuy",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await dinhKhoanCategoryService.UpsertDanhMucQuy(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);
export const XoaDanhMucQuy = createAsyncThunk(
  "dinhKhoanCategory/XoaDanhMucQuy",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await dinhKhoanCategoryService.XoaDanhMucQuy(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  dinhKhoanCateTree: [],
  dinhKhoanCate: [],
  dinhKhoanCateDetail: {
    loading: false,
    data: null,
  },
  DanhMucNgamDinhList: [],
  DanhMucQuyList: [],
};

const dinhKhoanCategorySlice = createSlice({
  name: "dinhKhoanCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //
      .addCase(GetAllDanhMucQuy.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllDanhMucQuy.fulfilled, (state, action) => {
        state.loading = false;
        state.DanhMucQuyList = action.payload;
      })
      .addCase(GetAllDanhMucQuy.rejected, (state) => {
        state.loading = false;
      })
      //
      .addCase(GetAllDanhMucNgamDinh.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllDanhMucNgamDinh.fulfilled, (state, action) => {
        state.loading = false;
        state.DanhMucNgamDinhList = action.payload;
      })
      .addCase(GetAllDanhMucNgamDinh.rejected, (state) => {
        state.loading = false;
      })
      //
      .addCase(getCateDinhKhoan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCateDinhKhoan.fulfilled, (state, action) => {
        state.loading = false;
        state.dinhKhoanCate = action.payload;
      })
      .addCase(getCateDinhKhoan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCateDinhKhoanTree.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCateDinhKhoanTree.fulfilled, (state, action) => {
        state.loading = false;
        state.dinhKhoanCateTree = action.payload;
      })
      .addCase(getCateDinhKhoanTree.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateCateDinhKhoan.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCateDinhKhoan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateCateDinhKhoan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createCateDinhKhoan.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCateDinhKhoan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createCateDinhKhoan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteCateDinhKhoan.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCateDinhKhoan.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteCateDinhKhoan.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getCateDinhKhoanById.pending, (state) => {
        state.dinhKhoanCateDetail = { ...state.dinhKhoanDetail, loading: true };
      })
      .addCase(getCateDinhKhoanById.fulfilled, (state, action) => {
        state.dinhKhoanCateDetail = { data: action.payload, loading: false };
      })
      .addCase(getCateDinhKhoanById.rejected, (state) => {
        state.dinhKhoanCateDetail = {
          ...state.dinhKhoanDetail,
          loading: false,
        };
      });
  },
});

export default dinhKhoanCategorySlice.reducer;
