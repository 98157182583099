import axiosInstance from "auth/FetchInterceptor";
import { NHAPXUAT_API } from "constants/ApiConstant";
const NhapXuatService = {};

NhapXuatService.searchGrid = function (data) {
  return axiosInstance.post(`${NHAPXUAT_API}/search-grid`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

NhapXuatService.getNhapXuatByManx = function (data) {
  return axiosInstance.get(
    `${NHAPXUAT_API}/get-by-manx?manx=${data.id}&idphieu=${data.idphieu}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

NhapXuatService.create = function (data) {
  return axiosInstance.post(NHAPXUAT_API, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
NhapXuatService.update = function (data) {
  return axiosInstance.put(NHAPXUAT_API, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
NhapXuatService.changeStateNhapXuat = function (data) {
  return axiosInstance.put(`${NHAPXUAT_API}/change-state`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
NhapXuatService.deleteNhapXuat = function (id) {
  return axiosInstance.delete(`${NHAPXUAT_API}/delete-by-manx?manx=${id}`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

export default NhapXuatService;
