import React from 'react';

const ImportSvgBtn = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            d="M4.75 6h2.5v5a.75.75 0 1 0 1.5 0V6h2.5a.25.25 0 0 0 .198-.402l-3.25-4.25a.259.259 0 0 0-.396 0l-3.25 4.25A.25.25 0 0 0 4.75 6Z"
        />
        <path
            fill="currentColor"
            d="M15 10.25a.25.25 0 0 0-.25.25v2.75a1 1 0 0 1-1 1H2.25a1 1 0 0 1-1-1V10.5a.25.25 0 0 0-.5 0v2.75a1.501 1.501 0 0 0 1.5 1.5h11.5a1.502 1.502 0 0 0 1.5-1.5V10.5a.25.25 0 0 0-.25-.25Z"
        />
    </svg>
);

export default ImportSvgBtn;



