import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import erpCommentService from "services/common/ErpCommentService";

export const GetAllGiftChat = createAsyncThunk(
  "erpComment/GetAllGiftChat",
  async (data, { rejectWithValue }) => {
    try {
      const response = await erpCommentService.getAllGiftChat(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const AddNewErpComment = createAsyncThunk(
  "erpComment/AddNewErpComment",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, ...payload } = data;
      const response = await erpCommentService.addComment(payload);
      if (onSuccess) {
        onSuccess();
      }
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const ReadAllErpComment = createAsyncThunk(
  "erpComment/ReadAllErpComment",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await erpCommentService.readAll(id);
      if (onSuccess) {
        onSuccess();
      }
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  data: {},
};

const erpCommentSlice = createSlice({
  name: "erpComment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetAllGiftChat.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllGiftChat.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(GetAllGiftChat.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default erpCommentSlice.reducer;
