import {
  GET_TRAINING_METHODS_API,
  UPDATE_TRAINING_METHOD_API,
  DELETE_TRAINING_METHOD_API,
  CREATE_TRAINING_METHOD_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const trainingMethodService = {};

trainingMethodService.Get = function () {
  return axiosInstance.get(`${GET_TRAINING_METHODS_API}`);
};
trainingMethodService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_TRAINING_METHOD_API}`, data);
};
trainingMethodService.Create = function (data) {
  return axiosInstance.post(`${CREATE_TRAINING_METHOD_API}`, data);
};
trainingMethodService.Delete = function (data) {
  return axiosInstance.delete(`${DELETE_TRAINING_METHOD_API}?id=${data}`);
};

export default trainingMethodService;
