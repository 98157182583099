import React from 'react';
import { Select } from "antd";
import { ChevronSvg } from 'components/Icon';

const MediSelect = (props) => {
    const handleChange = (value) => {
        if (props.onChange) {
            props.onChange(value);
        }
    }

    return (
        <Select {...props} suffixIcon={<ChevronSvg />} onChange={handleChange}>
            {props.children}
        </Select>
    );
}

export default MediSelect;