import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import PhieuThuService from "services/Finance/PhieuThuService";

export const GetAllPhieuThu = createAsyncThunk(
  "PhieuThu/GetAllPhieuThu",
  async (data, { rejectWithValue }) => {
    try {
      const response = await PhieuThuService.GetAllPhieuThu(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetPhieuThu = createAsyncThunk(
  "PhieuThu/GetPhieuThu",
  async (data, { rejectWithValue }) => {
    try {
      const response = await PhieuThuService.GetPhieuThu(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertPhieuThu = createAsyncThunk(
  "PhieuThu/UpsertPhieuThu",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuThuService.UpsertPhieuThu(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeletePhieuThu = createAsyncThunk(
  "PhieuThu/DeletePhieuThu",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuThuService.DeletePhieuThu(payload.id);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const GetPhieuThuChiTiet = createAsyncThunk(
  "PhieuThu/GetPhieuThuChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const response = await PhieuThuService.GetPhieuThuChiTiet(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertPhieuThuChiTiet = createAsyncThunk(
  "PhieuThu/UpsertPhieuThuChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuThuService.UpsertPhieuThuChiTiet(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeletePhieuThuChiTiet = createAsyncThunk(
  "PhieuThu/DeletePhieuThuChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuThuService.DeletePhieuThuChiTiet(payload.id);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  PhieuThuList: [],
  PhieuThuDetail: {},
  PhieuThuChiTietList: [],
};

const PhieuThuTienSlice = createSlice({
  name: "phieuThu",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //GetPhieuThuChiTiet
      .addCase(GetPhieuThuChiTiet.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetPhieuThuChiTiet.fulfilled, (state, action) => {
        state.loading = false;
        state.PhieuThuChiTietList = action.payload;
      })
      .addCase(GetPhieuThuChiTiet.rejected, (state) => {
        state.loading = false;
      })
      //GetPhieuThu
      .addCase(GetPhieuThu.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetPhieuThu.fulfilled, (state, action) => {
        state.loading = false;
        state.PhieuThuDetail = action.payload;
      })
      .addCase(GetPhieuThu.rejected, (state) => {
        state.loading = false;
      })
      //GetAllPhieuThu
      .addCase(GetAllPhieuThu.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllPhieuThu.fulfilled, (state, action) => {
        state.loading = false;
        state.PhieuThuList = action.payload;
      })
      .addCase(GetAllPhieuThu.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { showLoading, setHisInfo } = PhieuThuTienSlice.actions;

export default PhieuThuTienSlice.reducer;
