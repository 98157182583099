import axiosInstance from "auth/FetchInterceptor";
import {
  CHANGE_STATE_HSC1_API,
  CHANGE_STATE_HSC2_API,
  EXPORT_DSHSNV_API,
  EXPORT_HSNV_API,
  HRRECORD_ALL_API,
  HRRECORD_API,
  HRRECORD_HSC1_API,
  HRRECORD_HSC2_API,
} from "constants/ApiConstant";
const HRRecordService = {};

HRRecordService.getAllLevel = function () {
  return axiosInstance.get(`${HRRECORD_ALL_API}`);
};

HRRecordService.searchGrid = function () {
  return axiosInstance.get(`${HRRECORD_HSC1_API}`);
};

HRRecordService.addHSC1 = function (data) {
  return axiosInstance.post(`${HRRECORD_HSC1_API}`, data);
};

HRRecordService.updateHSC1 = function (data) {
  return axiosInstance.put(`${HRRECORD_HSC1_API}`, data);
};

HRRecordService.deleteHSC1 = function (id) {
  return axiosInstance.delete(`${HRRECORD_HSC1_API}?id=${id}`);
};

HRRecordService.changeStateHSC1 = function (id) {
  return axiosInstance.put(`${CHANGE_STATE_HSC1_API}?id=${id}`);
};

//Hoso2

HRRecordService.getAllDmHSC1 = function () {
  return axiosInstance.get(`${HRRECORD_HSC1_API}/dm-hsc1`);
};

HRRecordService.getAllDmHSC2 = function (id) {
  return axiosInstance.get(`${HRRECORD_HSC2_API}/dm-hsc2?id=${id}`);
};

HRRecordService.getAllHSC2 = function (data) {
  return axiosInstance.post(`${HRRECORD_HSC2_API}/search`, data);
};

HRRecordService.addHSC2 = function (data) {
  return axiosInstance.post(`${HRRECORD_HSC2_API}`, data);
};

HRRecordService.updateHSC2 = function (data) {
  return axiosInstance.put(`${HRRECORD_HSC2_API}`, data);
};

HRRecordService.deleteHSC2 = function (id) {
  return axiosInstance.delete(`${HRRECORD_HSC2_API}?id=${id}`);
};

HRRecordService.changeStateHSC2 = function (id) {
  return axiosInstance.put(`${CHANGE_STATE_HSC2_API}?id=${id}`);
};

HRRecordService.getAllHoSoNV = function (data) {
  return axiosInstance.post(`${HRRECORD_API}/ho-so-nhan-vien`, data);
};

HRRecordService.addHoSoNV = function (data) {
  return axiosInstance.post(`${HRRECORD_API}/ho-so-nhan-vien/add`, data);
};
HRRecordService.updateHoSoNV = function (data) {
  return axiosInstance.put(`${HRRECORD_API}/ho-so-nhan-vien`, data);
};

HRRecordService.deleteHoSoNV = function (id) {
  return axiosInstance.delete(`${HRRECORD_API}/ho-so-nhan-vien?id=${id}`);
};

HRRecordService.getExportHSNV = function (id) {
  return axiosInstance.get(`${EXPORT_HSNV_API}?id=${id}`);
};
HRRecordService.exportHSNV = function (data) {
  return axiosInstance.post(EXPORT_HSNV_API, data);
};

HRRecordService.exportDSHSNV = function (data) {
  return axiosInstance.post(EXPORT_DSHSNV_API, data);
};

export default HRRecordService;
