import { Button, Input, Popconfirm, Table } from "antd";

import React, { useMemo, useState } from "react";
import { styled } from "styled-components";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
// import { permitKey, PermitValue } from "constants";
import {
  createDmhoatchat,
  setAllDmhoatchatList,
  updateDmhoatchat,
  deleteDmhoatchat,
} from "store/slices/sale/danhmucSlice";

import { initDmhoatchat } from "views/app-views/sale/category/Dmhoatchat";
const formater = new Intl.NumberFormat("vi-VN");
// formatter and parser input number
export const formatterNumber = (val) => {
  if (!val) return 0;
  return formater.format(val);
};

// export const parserNumber = (val) => {
//   if (!val) return 0;
//   return Number.parseFloat(
//     val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")
//   ).toFixed(5);
// };
const TableDmhoatchatList = ({
  allDmhoatchatList,
  reloadData,
  permitList = [],
}) => {
  const [editingKey, setEditingKey] = useState("");
  //const isEditing = (record) => record.key === editingKey;

  const dispatch = useDispatch();

  const onChangeText = (type, record) => (e) => {
    const newData = allDmhoatchatList.slice(0);
    const value = e?.target?.value ?? e;
    const index1 = newData.findIndex(
      (item) => item.mahoatchat === record.mahoatchat
    );
    newData[index1] = { ...newData[index1], [type]: value };
    dispatch(setAllDmhoatchatList(newData));
  };
  const onClickAction = (record, index) => {
    const newData = allDmhoatchatList.slice(0);
    const payload = {
      mahoatchat: record.mahoatchat,
      tenhoatchat: record.tenhoatchat,
      viettat: record.viettat,
      sapxep: record.sapxep,
      onSuccess: () => {
        reloadData();
      },
    };
    if (record.action === initDmhoatchat.action) {
      dispatch(createDmhoatchat(payload));
    } else {
      if (record.mahoatchat !== null && record.action !== "update") {
        const index1 = newData.findIndex(
          (item) => item.mahoatchat === record.mahoatchat
        );
        newData[index1] = { ...newData[index1], action: "update" };
        dispatch(setAllDmhoatchatList(newData));
      } else {
        dispatch(updateDmhoatchat(payload));
      }
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        title: "STT",
        width: 50,
        align: "center",
        render: (_, __, index) => index + 1,
      },
      {
        title: "Mã số",
        width: 90,
        dataIndex: "mahoatchat",
      },
      {
        title: "Tên",
        width: 180,
        dataIndex: "tenhoatchat",
        render: (_, record, index) =>
          record.action ? (
            <Input
              style={{ width: "100%" }}
              value={record.tenhoatchat}
              placeholder="Nhập tên..."
              onChange={onChangeText("tenhoatchat", record)}
            />
          ) : (
            record.tenhoatchat
          ),
      },
      {
        fixed: "right",
        width: 90,
        align: "center",
        dataIndex: "action",
        render: (_, record, index) => (
          <>
            {record.action === "initial" ? (
              <Button
                //disabled={!record.tenhh || !allowView(PermitValue.them)}
                title={"Thêm thông tin"}
                onClick={() => onClickAction(record, index)}
                className="mr-2"
                icon={<PlusOutlined />}
                shape="circle"
              />
            ) : (
              <>
                <Button
                  disabled={
                    editingKey !== "" && editingKey !== record.mahoatchat
                  }
                  title={"Sửa thông tin"}
                  //disabled={!allowView(PermitValue.sua)}
                  onClick={() => {
                    setEditingKey(editingKey !== "" ? "" : record.mahoatchat);
                    onClickAction(record, index);
                  }}
                  className="mr-2"
                  icon={
                    record.action === "update" ? (
                      <SaveOutlined />
                    ) : (
                      <EditOutlined />
                    )
                  }
                  shape="circle"
                />
                <Popconfirm
                  title="Bạn có muốn xóa không?"
                  placement="topLeft"
                  onConfirm={() => {
                    dispatch(
                      deleteDmhoatchat({
                        id: record.mahoatchat,
                        onSuccess: (r) => {
                          reloadData();
                        },
                      })
                    );
                  }}
                >
                  <Button
                    disabled={editingKey !== ""}
                    title={"Xóa thông tin"}
                    //disabled={!allowView(PermitValue.xoa)}
                    className="mr-2"
                    icon={<DeleteOutlined type="primary" />}
                    shape="circle"
                  />
                </Popconfirm>
              </>
            )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allDmhoatchatList]
  );

  return (
    <CustomTable
      columns={tableColumns}
      dataSource={allDmhoatchatList}
      rowKey={(item) => item?.mahoatchat}
      bordered
      style={{ padding: "0px" }}
      fixed={true}
      scroll={{ x: "max-content" }}
    />
  );
};

const CustomTable = styled(Table)`
  .ant-table-tbody > tr > td {
    padding: 2px;
  }
`;

export default TableDmhoatchatList;
