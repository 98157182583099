import { DE_NGHI_UNG_API } from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const DeNghiTamUngService = {};

DeNghiTamUngService.GetAllDeNghiTamUng = function (data) {
  return axiosInstance.post(
    `${DE_NGHI_UNG_API}/get-all-de-nghi-tam-ung`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiTamUngService.GetDeNghiTamUng = function (data) {
  return axiosInstance.get(
    `${DE_NGHI_UNG_API}/get-de-nghi-tam-ung?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiTamUngService.UpsertDeNghiTamUng = function (data) {
  return axiosInstance.put(`${DE_NGHI_UNG_API}/up-sert-de-nghi-tam-ung`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

DeNghiTamUngService.DeleteDeNghiTamUng = function (data) {
  return axiosInstance.delete(
    `${DE_NGHI_UNG_API}/xoa-de-nghi-tam-ung?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiTamUngService.GetDeNghiTamUngChiTiet = function (data) {
  return axiosInstance.get(
    `${DE_NGHI_UNG_API}/get-de-nghi-tam-ung-chi-tiet?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiTamUngService.UpsertDeNghiTamUngChiTiet = function (data) {
  return axiosInstance.put(
    `${DE_NGHI_UNG_API}/up-sert-de-nghi-tam-ung-chi-tiet`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiTamUngService.DeleteDeNghiTamUngChiTiet = function (data) {
  return axiosInstance.delete(
    `${DE_NGHI_UNG_API}/xoa-de-nghi-tam-ung-chi-tiet?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

export default DeNghiTamUngService;
