import {
  CREATE_CHUC_DANH_CATEGORY_API,
  DELETE_CHUC_DANH_CATEGORY_API,
  GET_CHUC_DANH_CATEGORY_API,
  UPDATE_CHUC_DANH_CATEGORY_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const chucDanhCategoryService = {};

chucDanhCategoryService.Get = function () {
  return axiosInstance.get(`${GET_CHUC_DANH_CATEGORY_API}`);
};
chucDanhCategoryService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_CHUC_DANH_CATEGORY_API}`, data);
};
chucDanhCategoryService.Create = function (data) {
  return axiosInstance.post(`${CREATE_CHUC_DANH_CATEGORY_API}`, data);
};
chucDanhCategoryService.Delete = function (data) {
  return axiosInstance.delete(`${DELETE_CHUC_DANH_CATEGORY_API}?id=${data}`);
};

export default chucDanhCategoryService;
