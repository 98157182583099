import {
  CREATE_HOC_VAN_CATEGORY_API,
  DELETE_HOC_VAN_CATEGORY_API,
  GET_HOC_VAN_CATEGORY_API,
  UPDATE_HOC_VAN_CATEGORY_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const hocVanCategoryService = {};

hocVanCategoryService.Get = function () {
  return axiosInstance.get(`${GET_HOC_VAN_CATEGORY_API}`);
};
hocVanCategoryService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_HOC_VAN_CATEGORY_API}`, data);
};
hocVanCategoryService.Create = function (data) {
  return axiosInstance.post(`${CREATE_HOC_VAN_CATEGORY_API}`, data);
};
hocVanCategoryService.Delete = function (data) {
  return axiosInstance.delete(`${DELETE_HOC_VAN_CATEGORY_API}?id=${data}`);
};

export default hocVanCategoryService;
