import { Button, DatePicker, Input, Popconfirm, Spin } from "antd";
import React, { useEffect, useMemo } from "react";
import Utils from "utils";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteBangCongSystem,
  getBangCongSystem,
  setThietLap,
  upSertBangCongSystem,
} from "store/slices/bangCongSlice";
import { isEmpty } from "lodash";
import moment from "moment";
import { dateFormatList } from "constants";
import { PermitValue, permitKey } from "constants";
import MeditechTablePage from "components/table-layout";
import MediTable from "components/Custom/MediTable";

export const initThietLapBangCong = {
  action: "initial",
  isRequired: true,
};

const ThietLapBangCong = () => {
  const { branchId, permitList } = useSelector((state) => state.auth);
  const { loading, thietLap } = useSelector((state) => state.bangCong);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBangCongSystem(branchId));
  }, [branchId, dispatch]);

  const allow = Utils.checkPermitValue(
    PermitValue.chophep,
    permitList,
    permitKey.hrm_thietlapbangcong
  );

  const onChangeText = (type, index) => (e) => {
    const newData = thietLap.slice(0);
    const value = e?.target?.value.replace("-", "") || e;
    newData[index] = { ...newData[index], [type]: value };
    dispatch(setThietLap(newData));
  };

  const onFinish = (value, index) => {
    const newData = thietLap.slice(0);
    const payload = {
      id: value?.id ?? null,
      tongSoNgay: value.tongSoNgay,
      tongGioTangCa: value.tongGioTangCa,
      ngayApDung: value.ngayApDung,
      soNgayNghiLe: value.soNgayNghiLe,
      soGioLamMotCong: value.soGioLamMotCong,
      branchId,
      onSuccess: () => {
        dispatch(getBangCongSystem(branchId));
      },
    };
    if (!isEmpty(value.id) && value.action !== "update") {
      newData[index] = { ...newData[index], action: "update" };
      dispatch(setThietLap(newData));
    } else {
      dispatch(upSertBangCongSystem(payload));
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        title: "STT",
        align: "center",
        render: (_, __, index) => index + 1,
      },
      {
        title: "Số công tháng",
        dataIndex: "tongSoNgay",
        align: "center",
        render: (value, record, index) =>
          record.action ? (
            <Input
              value={value}
              type="number"
              min={0}
              onChange={onChangeText("tongSoNgay", index)}
              placeholder="Nhập số công"
            />
          ) : (
            value
          ),
      },
      {
        title: "Số giờ tăng ca tháng",
        dataIndex: "tongGioTangCa",
        align: "center",
        render: (value, record, index) =>
          record.action ? (
            <Input
              type="number"
              min={0}
              value={value}
              onChange={onChangeText("tongGioTangCa", index)}
              placeholder="Nhập số tăng ca"
            />
          ) : (
            value
          ),
      },
      {
        title: "Tổng số ngày nghỉ lễ trong năm",
        dataIndex: "soNgayNghiLe",
        align: "center",
        render: (value, record, index) =>
          record.action ? (
            <Input
              type="number"
              min={0}
              value={value}
              onChange={onChangeText("soNgayNghiLe", index)}
              placeholder="Nhập tổng số ngày nghỉ lễ 1 năm"
            />
          ) : (
            value
          ),
      },
      {
        title: "Số giờ một công",
        dataIndex: "soGioLamMotCong",
        align: "center",
        render: (value, record, index) =>
          record.action ? (
            <Input
              type="number"
              min={0}
              value={value}
              onChange={onChangeText("soGioLamMotCong", index)}
              placeholder="Nhập số giờ làm một công"
            />
          ) : (
            value
          ),
      },
      {
        title: "Ngày áp dụng",
        align: "center",
        dataIndex: "ngayApDung",
        render: (value, record, index) =>
          record.action ? (
            <DatePicker
              format={dateFormatList}
              value={value && moment(value)}
              style={{ width: "100%" }}
              onChange={onChangeText("ngayApDung", index)}
              placeholder="Chọn ngày áp dụng"
            />
          ) : (
            Utils.formatDate(value, true)
          ),
      },
      {
        title: "Người tạo",
        align: "center",
        dataIndex: "createdName",
      },
      {
        title: "Thời gian tạo",
        align: "center",
        dataIndex: "creadtedAt",
        render: (date) => date && Utils.formatDate(date),
      },
      {
        fixed: "right",
        align: "center",
        dataIndex: "action",
        render: (_, record, index) => (
          <>
            {record.action === "initial" ? (
              <Button
                onClick={() => onFinish(record)}
                disabled={
                  !allow ||
                  !record.tongSoNgay ||
                  !record.ngayApDung ||
                  !record.tongGioTangCa
                }
                title={"Thêm thông tin"}
                className="mr-2"
                icon={<PlusOutlined />}
                shape="circle"
              />
            ) : (
              <>
                <Button
                  disabled={!allow}
                  onClick={() => onFinish(record, index)}
                  title={"Sửa thông tin"}
                  className="mr-2"
                  icon={
                    record.action === "update" ? (
                      <SaveOutlined />
                    ) : (
                      <EditOutlined />
                    )
                  }
                  shape="circle"
                />
                <Popconfirm
                  disabled={!allow}
                  title="Bạn có muốn xóa không?"
                  placement="topLeft"
                  onConfirm={() => {
                    dispatch(
                      DeleteBangCongSystem({
                        id: record.id,
                        onSuccess: () => dispatch(getBangCongSystem(branchId)),
                      })
                    );
                  }}
                >
                  <Button
                    disabled={!allow}
                    title={"Xóa thông tin"}
                    className="mr-2"
                    icon={<DeleteOutlined type="primary" />}
                    shape="circle"
                  />
                </Popconfirm>
              </>
            )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [thietLap]
  );
  return (
    <Spin tip="Đang tải..." spinning={loading}>
      <MeditechTablePage tableTitle="THIẾT LẬP BẢNG CÔNG">
        <MediTable tableColumns={tableColumns} dataSource={thietLap} />
      </MeditechTablePage>
    </Spin>
  );
};

export default ThietLapBangCong;
