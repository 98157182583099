import { Button } from "antd";

const MediActionCancel = (props) => {
  const handleClick = (e) => {
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <Button {...props} onClick={handleClick} type="text">
      {props.children}
    </Button>
  );
};

export default MediActionCancel;
