const FirebaseConfig = {
  apiKey: "AIzaSyDz9eR_-cPDX-hCQwu9UxHtXbDlw61LCx8",
  authDomain: "medicare-dev-7d0b8.firebaseapp.com",
  projectId: "medicare-dev-7d0b8",
  storageBucket: "medicare-dev-7d0b8.appspot.com",
  messagingSenderId: "180913692300",
  appId: "1:180913692300:web:302b56976041ed582125a9",
  measurementId: "G-C2RTNYSDES",
};
export const REACT_APP_VAPID_KEY =
  "BHVXjRHntlyMbnDPCo8hXCGgpiVmeNE10D5_FmrbTTk_9AqghZjp6mwZWIUR18jZjfKBT3M53ykLvVMpj-al6Gg";

export default FirebaseConfig;
