import {
  GET_PARTNER_BY_ID_API,
  GET_PARTNERS_API,
  UPDATE_PARTNER_API,
  CREATE_PARTNER_API,
  DELETE_PARTNER_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const partnerService = {};

partnerService.Get = function (data) {
  return axiosInstance.post(`${GET_PARTNERS_API}`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
partnerService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_PARTNER_API}`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
partnerService.Create = function (data) {
  return axiosInstance.post(`${CREATE_PARTNER_API}`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
partnerService.Delete = function (data) {
  return axiosInstance.delete(`${DELETE_PARTNER_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
partnerService.GetById = function (data) {
  return axiosInstance.get(`${GET_PARTNER_BY_ID_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
export default partnerService;
