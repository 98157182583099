import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { cloneDeep } from "lodash";
import chucVuCategoryService from "services/thietLapKhac/ChucVuCategoryService";

export const getDanhMucChucVu = createAsyncThunk(
  "chucVuCategory/getDanhMucChucVu",
  async (branchId, { rejectWithValue }) => {
    try {
      const response = await chucVuCategoryService.Get(branchId);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateDanhMucChucVu = createAsyncThunk(
  "chucVuCategory/updateDanhMucChucVu",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await chucVuCategoryService.Update(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createDanhMucChucVu = createAsyncThunk(
  "chucVuCategory/createDanhMucChucVu",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await chucVuCategoryService.Create(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const deleteDanhMucChucVu = createAsyncThunk(
  "chucVuCategory/deleteDanhMucChucVu",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await chucVuCategoryService.Delete(id);
      if (onSuccess) onSuccess(response);
      return id;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  danhMucChucVuList: [],
  danhMucChucVuListSetting: [],
  error: null,
};

const chucVuCategorySlice = createSlice({
  name: "chucVuCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDanhMucChucVu.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDanhMucChucVu.fulfilled, (state, action) => {
        state.loading = false;
        const payload = action.payload || [];
        const filteredPayload = payload.filter(
          (item) => item.IsActivated === true
        );
        state.danhMucChucVuList = filteredPayload;
        state.danhMucChucVuListSetting = [
          {
            action: "initial",
            isRequired: true,
          },
          ...payload,
        ];
      })
      .addCase(getDanhMucChucVu.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateDanhMucChucVu.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDanhMucChucVu.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateDanhMucChucVu.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createDanhMucChucVu.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDanhMucChucVu.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createDanhMucChucVu.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteDanhMucChucVu.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDanhMucChucVu.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteDanhMucChucVu.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default chucVuCategorySlice.reducer;
