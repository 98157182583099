import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import EmployeeTransferService from "services/employee/EmployeeTransferService";
import CategoryService from "services/CategoryService";

export const searchGridAPI = createAsyncThunk(
  "employeeTransfer/searchGridAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await EmployeeTransferService.searchGrid(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getDetailAPI = createAsyncThunk(
  "employeeTransfer/getDetailAPI",
  async (id, { rejectWithValue }) => {
    try {
      const response = await EmployeeTransferService.detail(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const upSertEmployeeTransferApi = createAsyncThunk(
  "employeeTransfer/upSertEmployeeTransferApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, ...payload } = data;
      const response = await EmployeeTransferService.upSert(payload);
      if (onSuccess) onSuccess(response.data.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateEmployeeTransferSyncApi = createAsyncThunk(
  "employeeTransfer/updateEmployeeTransferSyncApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await EmployeeTransferService.updateEmployeeSync(id);
      if (onSuccess) onSuccess(response.data.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const deleteEmployeeTransferAPI = createAsyncThunk(
  "employeeTransfer/deleteEmployeeTransferAPI",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const response = await EmployeeTransferService.delete(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getDepartmentByBranchTransfer = createAsyncThunk(
  "category/getDepartmentByBranchTransfer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await CategoryService.getDepartmentByBranch(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  list: [],
  detail: [],
  deptByBranchList: [],
};

export const employeeTransferSlice = createSlice({
  name: "employeeTransfer",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setHisInfo: (state, action) => {
      state.hisInfoList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchGridAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchGridAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(searchGridAPI.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(upSertEmployeeTransferApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(upSertEmployeeTransferApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(upSertEmployeeTransferApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getDetailAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDetailAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.detail = action.payload;
      })
      .addCase(getDetailAPI.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteEmployeeTransferAPI.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteEmployeeTransferAPI.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteEmployeeTransferAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateEmployeeTransferSyncApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateEmployeeTransferSyncApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateEmployeeTransferSyncApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDepartmentByBranchTransfer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDepartmentByBranchTransfer.fulfilled, (state, action) => {
        state.loading = false;
        state.deptByBranchList = action.payload;
      })
      .addCase(getDepartmentByBranchTransfer.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setHisInfo } = employeeTransferSlice.actions;
export const employeeTransferSelector = (state) => state.employeeTransfer;
export default employeeTransferSlice.reducer;
