import { Button, Popconfirm, Table, Tag } from "antd";
import React, { useMemo } from "react";
import { styled } from "styled-components";
import {
  EditOutlined,
  DeleteOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import Utils from "utils";
import { deleteNhapXuat } from "store/slices/sale/nhapxuatSlice";
import { deleteSignedDocument } from "store/slices/signatureProcessSlice";
import { DOCUMENT_TYPE_ID } from "constants";

export default function TablenhapKhoList({
  setXuatKhoId,
  setMaKkxuat,
  setNgayxuat,
  nhapXuatList,
  setVisibleModal,
  visibleModal,
  reloadData,
  permitList = [],
  setIsPrinter,
  setPrintData,
}) {
  const dispatch = useDispatch();

  const tableColumns = useMemo(
    () => [
      {
        title: "STT",
        width: 50,
        align: "center",
        render: (_, __, index) => index + 1,
      },
      {
        title: "Số phiếu",
        width: 90,
        dataIndex: "sophieu",
      },
      {
        title: "Ngày",
        width: 120,
        dataIndex: "ngay",
        render: (value) => Utils.formatDate(value),
      },
      {
        title: "Kho xuất",
        width: 160,
        dataIndex: "tenkk",
      },
      {
        title: "Đơn vị giao",
        width: 180,
        dataIndex: "tenkkgiao",
      },
      {
        title: "Đơn vị nhận",
        width: 180,
        dataIndex: "tenkknhan",
      },
      {
        title: "Người giao",
        width: 100,
        dataIndex: "nguoigiao",
      },
      {
        title: "Trạng thái",
        width: 80,
        dataIndex: "daduyet",
        align: "center",
        render: (value) => (
          <Tag color={value ? "green" : "red"}>
            {value ? "Đã duyệt" : "Chưa duyệt"}
          </Tag>
        ),
      },
      {
        width: 150,
        fixed: "right",
        align: "center",
        render: (_, record) => (
          <>
            <Button
              title={"Sửa thông tin phiếu"}
              onClick={() => {
                setVisibleModal(true);
                setXuatKhoId(record.manx);
                setMaKkxuat(record.makk);
                setNgayxuat(record.ngay);
              }}
              className="mr-2"
              icon={<EditOutlined />}
              shape="circle"
            />
            <Popconfirm
              title="Bạn muốn xóa phiếu?"
              placement="topLeft"
              onConfirm={() => {
                dispatch(
                  deleteNhapXuat({
                    id: record.manx,
                    onSuccess: () => {
                      const item = {
                        documentId: record.idphieu,
                        documentTypeId: DOCUMENT_TYPE_ID.XuatHang,
                      };
                      dispatch(deleteSignedDocument(item));
                      reloadData();
                    },
                  })
                );
              }}
            >
              <Button
                title={"Xóa phiếu"}
                className="mr-2"
                icon={<DeleteOutlined type="primary" />}
                shape="circle"
              />
            </Popconfirm>
            <Button
              title={"In phiếu"}
              className="mr-2"
              onClick={() => {
                setIsPrinter(true);
                setPrintData({
                  idphieu: record.idphieu,
                  manx: record.manx,
                  sophieu: record.sophieu,
                });
              }}
              icon={<PrinterOutlined type="primary" />}
              shape="circle"
            />
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nhapXuatList]
  );

  return (
    <CustomTable
      columns={tableColumns}
      dataSource={nhapXuatList}
      scroll={{ x: "max-content" }}
      rowKey={(item) => item?.id}
    />
  );
}

const CustomTable = styled(Table)`
  .avatar-status:hover {
    text-decoration: underline;
  }
`;
