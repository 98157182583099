import { BAO_CAO_TAI_CHINH_API } from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const BaoCaoThuChiService = {};

BaoCaoThuChiService.GetSoDuTaiKhoan = function (data) {
  return axiosInstance.post(
    `${BAO_CAO_TAI_CHINH_API}/bao-cao-so-du-tai-khoan`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

export default BaoCaoThuChiService;
