import axiosInstance from "auth/FetchInterceptor";

const { ERP_COMMENT_API } = require("constants/ApiConstant");

const erpCommentService = {};

erpCommentService.getAllGiftChat = function (id) {
  return axiosInstance.get(
    `${ERP_COMMENT_API}/get-gift-chat?id=${id}&&isWeb=true`,
    {
      baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
    }
  );
};

erpCommentService.addComment = function (data) {
  return axiosInstance.post(`${ERP_COMMENT_API}/add`, data, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};

erpCommentService.readAll = function (id) {
  return axiosInstance.get(`${ERP_COMMENT_API}/read-all?id=${id}`, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};

export default erpCommentService;
