import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import MarketingCampaignService from "services/crm/marketingCampaignService";

export const searchCallHistory = createAsyncThunk(
  "mktCampaign/searchCallHistory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MarketingCampaignService.searchCallHistory(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const searchCampaign = createAsyncThunk(
  "mktCampaign/searchCampaign",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MarketingCampaignService.searchCampaign(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const getCampaignById = createAsyncThunk(
  "mktCampaign/getCampaignById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await MarketingCampaignService.getCampaignById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const addCampaign = createAsyncThunk(
  "mktCampaign/addCampaign",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await MarketingCampaignService.addCampaign(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateCampaign = createAsyncThunk(
  "mktCampaign/updateCampaign",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MarketingCampaignService.updateCampaign(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const deleteCampaign = createAsyncThunk(
  "mktCampaign/deleteCampaign",
  async (data, { rejectWithValue }) => {
    try {
      const { id, onSuccess } = data;
      const response = await MarketingCampaignService.deleteCampain(id);
      if (onSuccess) onSuccess();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const copyCampain = createAsyncThunk(
  "mktCampaign/copyCampain",
  async (data, { rejectWithValue }) => {
    try {
      const { id, onSuccess } = data;
      const response = await MarketingCampaignService.copyCampain(id);
      if (onSuccess) onSuccess();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const deleteOutboundCall = createAsyncThunk(
  "mktCampaign/deleteOutboundCall",
  async (payload, { rejectWithValue }) => {
    try {
      const { data, onSuccess } = payload;
      const response = await MarketingCampaignService.deleteOutboundCall(data);
      if (onSuccess) onSuccess();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
const initialState = {
  loading: false,
  campaignList: [],
  campaignDetail: {},
  callHistoryData: {},
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // search call history
      .addCase(searchCallHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchCallHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.callHistoryData = action.payload;
      })
      .addCase(searchCallHistory.rejected, (state, action) => {
        state.loading = false;
      })
      // search
      .addCase(searchCampaign.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchCampaign.fulfilled, (state, action) => {
        state.loading = false;
        console.log('action.payload', action.payload);
        state.campaignList = action.payload;
      })
      .addCase(searchCampaign.rejected, (state, action) => {
        state.loading = false;
      })
      // add new campaign
      .addCase(addCampaign.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCampaign.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addCampaign.rejected, (state, action) => {
        state.loading = false;
      })

       // add new campaign
       .addCase(updateCampaign.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCampaign.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateCampaign.rejected, (state, action) => {
        state.loading = false;
      })

      // get campaign by id
      .addCase(getCampaignById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCampaignById.fulfilled, (state, action) => {
        state.loading = false;
        state.campaignDetail = action.payload;
      })
      .addCase(getCampaignById.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = campaignSlice.actions;

export default campaignSlice.reducer;
