import { DE_XUAT_THANHTOAN_API } from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const DeXuatThanhToanService = {};

DeXuatThanhToanService.GetAllDeXuatThanhToan = function (data) {
  return axiosInstance.post(
    `${DE_XUAT_THANHTOAN_API}/get-all-de-xuat-thanh-toan`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeXuatThanhToanService.GetDeXuatThanhToan = function (data) {
  return axiosInstance.get(
    `${DE_XUAT_THANHTOAN_API}/get-de-xuat-thanh-toan?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeXuatThanhToanService.UpsertDeXuatThanhToan = function (data) {
  return axiosInstance.put(
    `${DE_XUAT_THANHTOAN_API}/up-sert-de-xuat-thanh-toan`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeXuatThanhToanService.DeleteDeXuatThanhToan = function (data) {
  return axiosInstance.delete(
    `${DE_XUAT_THANHTOAN_API}/xoa-de-xuat-thanh-toan?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeXuatThanhToanService.GetDeXuatThanhToanChiTiet = function (data) {
  return axiosInstance.get(
    `${DE_XUAT_THANHTOAN_API}/get-de-xuat-thanh-toan-chi-tiet?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeXuatThanhToanService.UpsertDeXuatThanhToanChiTiet = function (data) {
  return axiosInstance.put(
    `${DE_XUAT_THANHTOAN_API}/up-sert-de-xuat-thanh-toan-chi-tiet`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeXuatThanhToanService.DeleteDeXuatThanhToanChiTiet = function (data) {
  return axiosInstance.delete(
    `${DE_XUAT_THANHTOAN_API}/xoa-de-xuat-thanh-toan-chi-tiet?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

export default DeXuatThanhToanService;
