import { CHUNG_TU_NGHIEP_VU_KHAC_API } from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const ChungTuNghiepVuKhac = {};

ChungTuNghiepVuKhac.GetAllChungTuNghiepVuKhac = function (data) {
  return axiosInstance.post(
    `${CHUNG_TU_NGHIEP_VU_KHAC_API}/get-all-chung-tu-nghiep-vu-khac`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

ChungTuNghiepVuKhac.GetChungTuNghiepVuKhac = function (data) {
  return axiosInstance.get(
    `${CHUNG_TU_NGHIEP_VU_KHAC_API}/get-chung-tu-nghiep-vu-khac?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

ChungTuNghiepVuKhac.UpsertChungTuNghiepVuKhac = function (data) {
  return axiosInstance.put(
    `${CHUNG_TU_NGHIEP_VU_KHAC_API}/up-sert-chung-tu-nghiep-vu-khac`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

ChungTuNghiepVuKhac.DeleteChungTuNghiepVuKhac = function (data) {
  return axiosInstance.delete(
    `${CHUNG_TU_NGHIEP_VU_KHAC_API}/xoa-chung-tu-nghiep-vu-khac?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

ChungTuNghiepVuKhac.GetChungTuNghiepVuKhacChiTiet = function (data) {
  return axiosInstance.get(
    `${CHUNG_TU_NGHIEP_VU_KHAC_API}/get-chung-tu-nghiep-vu-khac-chi-tiet?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

ChungTuNghiepVuKhac.UpsertChungTuNghiepVuKhacChiTiet = function (data) {
  return axiosInstance.put(
    `${CHUNG_TU_NGHIEP_VU_KHAC_API}/up-sert-chung-tu-nghiep-vu-khac-chi-tiet`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

ChungTuNghiepVuKhac.DeleteChungTuNghiepVuKhacChiTiet = function (data) {
  return axiosInstance.delete(
    `${CHUNG_TU_NGHIEP_VU_KHAC_API}/xoa-chung-tu-nghiep-vu-khac-chi-tiet?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

export default ChungTuNghiepVuKhac;
