import React from "react"

const UserSignSvg = (props) => (
    <span className="anticon">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={12}
            height={12}
            fill="none"
            {...props}
        >
            <path
                fill="#181918"
                d="M5.25 5.25a2.625 2.625 0 1 0 0-5.25 2.625 2.625 0 0 0 0 5.25ZM7.532 11.969A1.879 1.879 0 0 1 6 10.125v-.75c0-.916.662-1.681 1.532-1.843.035-.191.104-.369.192-.535a5.231 5.231 0 0 0-2.474-.622A5.256 5.256 0 0 0 0 11.625c0 .207.168.375.375.375H7.54l-.008-.031Z"
            />
            <path
                fill="#181918"
                d="M11.625 9H10.5V7.875a.375.375 0 0 0-.375-.375h-.75A.375.375 0 0 0 9 7.875V9H7.875a.375.375 0 0 0-.375.375v.75c0 .207.168.375.375.375H9v1.125c0 .207.168.375.375.375h.75a.375.375 0 0 0 .375-.375V10.5h1.125a.375.375 0 0 0 .375-.375v-.75A.375.375 0 0 0 11.625 9Z"
            />
        </svg>
    </span>
)
export default UserSignSvg