import {
  CREATE_CHUYEN_MON_CATEGORY_API,
  DELETE_CHUYEN_MON_CATEGORY_API,
  GET_CHUYEN_MON_CATEGORY_API,
  UPDATE_CHUYEN_MON_CATEGORY_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const chuyenMonCategoryService = {};

chuyenMonCategoryService.Get = function () {
  return axiosInstance.get(`${GET_CHUYEN_MON_CATEGORY_API}`);
};
chuyenMonCategoryService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_CHUYEN_MON_CATEGORY_API}`, data);
};
chuyenMonCategoryService.Create = function (data) {
  return axiosInstance.post(`${CREATE_CHUYEN_MON_CATEGORY_API}`, data);
};
chuyenMonCategoryService.Delete = function (data) {
  return axiosInstance.delete(`${DELETE_CHUYEN_MON_CATEGORY_API}?id=${data}`);
};

export default chuyenMonCategoryService;
