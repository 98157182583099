import React, { memo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSignedDocument,
  updateSignedDocument,
  reloadSignedDocument,
} from "store/slices/signatureProcessSlice";
import { Modal, Form, Input } from "antd";
import Sign from "./Sign";
import { isEmpty } from "lodash";
import { SIGNATURE_STATUS } from "constants";
import ModalEmployeeSignProcess from "views/app-views/hrm/employee/components/ModalEmployeeSignProcess";
import { updateSignatureProcess } from "store/slices/internalSlice";
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
const { confirm } = Modal;

const SignProcess = ({
  className,
  documentTypeId,
  documentId,
  onAllSigned,
  onOneSigned,
  onSetSignData,
  onValidBeforeSign, // return true/false before Sign
  forceUpdate,
  onSignReloadForm,
}) => {
  const dispatch = useDispatch();
  const [signProcess, setSignProcess] = useState();
  const [selectedSign, setSelectedSign] = useState();
  const [employeeId, setEmployeeId] = useState();
  const [employeeSignList, setEmployeeSignList] = useState();
  const [visibleEmployee, setVisibleEmployee] = useState(false);

  const { branchId } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  // console.log(documentId,"documentId")
  // useEffect(() => {
  //   dispatch(getAllEmployee({ SearchText: null, branchId }));
  // }, [dispatch, branchId]);

  useEffect(() => {
    if (documentTypeId > 0) {
      reloadData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTypeId, forceUpdate, documentId]);

  const reloadData = useCallback(() => {
    const payload = {
      documentTypeId: documentTypeId,
      documentId: !isEmpty(documentId) ? documentId : null,
      branchId: branchId,
      onSuccess: (result) => {
        onSetSignData(result);
        setSignProcess(result);
      },
    };

    dispatch(getSignedDocument(payload));
  }, [branchId, dispatch, documentId, documentTypeId, onSetSignData]);

  const reloadSignProcessClick = () => {
    const payload = {
      documentTypeId: documentTypeId,
      documentId: !isEmpty(documentId) ? documentId : null,
      branchId: branchId,
      onSuccess: (result) => {
        const payloadTotal = {
          documentType: documentTypeId,
          objectId: documentId,
          totalSigns: result.totalSigns,
          totalSigned: result.totalSigned,
        };
        dispatch(updateSignatureProcess(payloadTotal));

        reloadData(payload);
      },
    };

    dispatch(reloadSignedDocument(payload));
  }

  const onSignClick = (item) => () => {
    if (!item.allowSign || documentTypeId == null || !item.isNextSign) return;

    // Phải ký theo thứ tự
    if (!item.isNextSign) return;

    confirm({
      icon: <></>,
      centered: true,
      content: (

        <>
          <div style={{ fontWeight: 500, marginBottom: 12 }}>
            Bạn có chắc chắn muốn ký tại vị trí này không?
          </div>
          <Form form={form} layout="vertical" onFinish={onFinishHandle}>
            <Form.Item name="reason">
              <Input.TextArea placeholder="Nhắc nhở nếu có" />
            </Form.Item>
          </Form>
        </>
      ),
      onOk() {
        return form
          .validateFields()
          .then((values) => {
            onFinishHandle(values, item);
          })
          .catch((errorInfo) => {
            return Promise.reject();
          });

        // const payload = {
        //   signedDocumentId: item.id,
        //   signed: true,
        //   onSuccess: ({ data }) => {
        //     const payloadTotal = {
        //       documentType: documentTypeId,
        //       objectId: documentId,
        //       totalSigns: data.totalSigns,
        //       totalSigned: data.totalSigned,
        //       documentId: item.id,
        //       onSuccess: () => {
        //         reloadData();
        //         if (onSignReloadForm) onSignReloadForm(data);
        //       },
        //     };

        //     dispatch(updateSignatureProcess(payloadTotal));
        //   },
        // };

        // dispatch(updateSignedDocument(payload));
      },
      // onCancel() {
      //     console.log('Cancel');
      // },
    });
  };

  const onFinishHandle = useCallback(
    (values, item) => {
      const payload = {
        signedDocumentId: item.id,
        signed: true,
        description: values.reason,
        onSuccess: ({ data }) => {

          form.resetFields();

          const payloadTotal = {
            documentType: documentTypeId,
            objectId: documentId,
            totalSigns: data.totalSigns,
            totalSigned: data.totalSigned,
            documentId: item.id,
            onSuccess: () => {
              if (reloadData) reloadData();
              if (onSignReloadForm) onSignReloadForm(data);
            },
          };

          dispatch(updateSignatureProcess(payloadTotal));
        },
      };

      dispatch(updateSignedDocument(payload));
    },
    [dispatch, reloadData, form, documentId, documentTypeId, onSignReloadForm]
  );

  const onChangeSigner = (item) => () => {
    if (documentTypeId == null || item.signed === SIGNATURE_STATUS.Signed)
      return;

    setSelectedSign(item);

    setEmployeeSignList(item.signerEmployees);

    if (item.signerId) setEmployeeId([item.signerId]);

    setVisibleEmployee(true);
  };

  const onSignerSelected = () => {
    if (signProcess == null || !employeeId) return;

    const payload = {
      signedDocumentId: selectedSign.id,
      authorizedSignatoryId: employeeId[0],
      onSuccess: () => {
        reloadData();
      },
    };

    dispatch(updateSignedDocument(payload));
  };

  const onShowMoreHandle = (title, reason) => {
    Modal.info({
      icon: <></>,
      content: (
        <>
          <div
            style={{
              fontSize: 16,
              lineHeight: "24px",
              fontWeight: 500,
              marginBottom: 12,
              textAlign: "center",
            }}
          >
            {title}
          </div>
          <div>{reason}</div>
        </>
      ),
      centered: true,
      onOk() { },
    });
  };

  if (signProcess == null) return null;

  return (
    <div className="d-flex">
      <div className="d-flex flex-fill">
        {signProcess &&
          signProcess?.signaturePositionGroupByOrder?.map((item, index) => {
            return (
              <div
                key={`${index}-group-step`}
                style={{
                  width: `${(item.totalSign / signProcess.totalSign) * 100}%`,
                }}
                className="sign-group-step"
              >
                {item.signatureProcesses &&
                  item.signatureProcesses.map((sign, index) => {
                    const enable = item.isNextSign;
                    return (
                      <div
                        key={`${index}-step`}
                        style={{
                          width: `${100 / item.totalSign}%`,
                        }}
                      >
                        <Sign
                          signInfo={sign}
                          onSignClick={onSignClick(sign, index)}
                          onChangeSigner={onChangeSigner(sign, index)}
                          onShowMore={onShowMoreHandle}
                          enable={enable}
                        />
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </div>
      <div style={{ width: "280px" }} className="d-flex align-items-end justify-content-end">
        <Button size='small' icon={<ReloadOutlined />} onClick={(e) => { e.stopPropagation(); reloadSignProcessClick(); }}>Cập nhật</Button>
      </div>
      <ModalEmployeeSignProcess
        visibleModal={visibleEmployee}
        selectedRowKeys={employeeId}
        setSelectedRowKeys={setEmployeeId}
        onCancel={() => {
          setVisibleEmployee((prev) => !prev);
          setEmployeeId(null);
        }}
        onOk={() => {
          setVisibleEmployee((prev) => !prev);
          onSignerSelected();
        }}
        // branchId={branchId}
        isDeptByBranch
        type="radio"
        employeeIds={employeeSignList}
      />
    </div>
  );
};

export default memo(SignProcess);
