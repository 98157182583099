import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
// import moment from "moment";
import KeHoachMuaHangService from "services/sale/KeHoachMuaHangService";
import { v4 as uuidv4 } from "uuid";
//#region kehoachmuahang

export const create = createAsyncThunk(
  "kehoachmuahang/create",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KeHoachMuaHangService.create(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const update = createAsyncThunk(
  "kehoachmuahang/update",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KeHoachMuaHangService.update(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getall = createAsyncThunk(
  "kehoachmuahang/getall",
  async (data, { rejectWithValue }) => {
    try {
      const response = await KeHoachMuaHangService.getall(data);
      return response?.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getbyid = createAsyncThunk(
  "kehoachmuahang/getbyid",
  async (data, { rejectWithValue }) => {
    try {
      const response = await KeHoachMuaHangService.getbyid(data);
      if (response.data.isOk) return response?.data?.data;
      else rejectWithValue("Error");
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const deletebyid = createAsyncThunk(
  "kehoachmuahang/deletebyid",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KeHoachMuaHangService.delete(payload.id);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

//#endregion

export const createchitet = createAsyncThunk(
  "kehoachmuahang/createchitet",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      delete payload.onError;
      const response = await KeHoachMuaHangService.createChiTiet(data);
      if (onSuccess) onSuccess(response.data.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getdsdexuatbyid = createAsyncThunk(
  "kehoachmuahang/getdsdexuatbyid",
  async (data, { rejectWithValue }) => {
    try {
      const response = await KeHoachMuaHangService.getDsChiTietByID(data);
      return response?.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetAllKeHoach = createAsyncThunk(
  "kehoachmuahang/GetAllKeHoach",
  async (data, { rejectWithValue }) => {
    try {
      const response = await KeHoachMuaHangService.GetAllKeHoach();
      return response?.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const deleteDeXuatChiTiet = createAsyncThunk(
  "kehoachmuahang/deleteDeXuatChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KeHoachMuaHangService.deleteDeXuatChiTiet(
        payload.id
      );
      if (onSuccess) onSuccess(response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const addrow = createAsyncThunk(
  "kehoachmuahang/addrow",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      let item = state.kehoachmuahang.chitietkehoachmuahanglist.find(
        (x) => x.tenhanghoa === null
      );
      if (data && !item) {
        const newData = {
          id: uuidv4(),
          mahh: null,
          tenhanghoa: null,
          soluong: null,
          ghichu: null,
          donvitinh: null,
          giadukien: null,
          thanhtien: null,
          idnhacungcap: null,
          stt: state.kehoachmuahang.chitietkehoachmuahanglist.length + 1,
        };
        return newData;
      }
      return rejectWithValue();
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getDmHangHoa = createAsyncThunk(
  "kehoachmuahang/getDmHangHoa",
  async (data, { rejectWithValue }) => {
    try {
      const response = await KeHoachMuaHangService.getDmHangHoa(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getGiaNhapGanNhat = createAsyncThunk(
  "kehoachmuahang/getGiaNhapGanNhat",
  async (data, { rejectWithValue }) => {
    try {
      const response = await KeHoachMuaHangService.getGiaNhapGanNhat(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const putXacNhanChiTiet = createAsyncThunk(
  "kehoachmuahang/putXacNhanChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const response = await KeHoachMuaHangService.xacNhanChiTiet(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

//#endregion
const initialState = {
  loading: false,
  kehoachmuahanglist: [],
  kehoachmuahangdetail: {},
  totalcount: 0,
  chitietkehoachmuahanglist: [],
  bangchitietkehoachmuahang: [],
  thongTinCheckXuLyDonHang: null,
  tongtiendexuat: 0,
  tongtienduocduyet: 0,
  KeHoachList: [],
};

export const kehoachmuahangSlice = createSlice({
  name: "kehoachmuahang",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    reSetDataChiTietkehoachmuahang: (state) => {
      state.chitietkehoachmuahanglist = [];
    },
    updateRowChiTietDeXuat: (state, action) => {
      var data = cloneDeep(action.payload);
      // const { onSuccess, onError } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      delete payload.onError;
      state.chitietkehoachmuahanglist = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllKeHoach.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllKeHoach.fulfilled, (state, action) => {
        state.KeHoachList = action.payload;
        state.loading = false;
      })
      .addCase(GetAllKeHoach.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(create.pending, (state) => {
        state.loading = true;
      })
      .addCase(create.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(create.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getall.pending, (state) => {
        state.loading = true;
      })
      .addCase(getall.fulfilled, (state, action) => {
        state.loading = false;
        state.kehoachmuahanglist = action.payload?.data.data;
        state.totalcount = action.payload?.data.totalCount;
        state.bangchitietkehoachmuahang = action.payload.detail;
      })
      .addCase(getall.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getbyid.pending, (state) => {
        state.loading = true;
      })
      .addCase(getbyid.fulfilled, (state, action) => {
        state.loading = false;
        state.kehoachmuahangdetail = action.payload;
      })
      .addCase(getbyid.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(update.pending, (state) => {
        state.loading = true;
      })
      .addCase(update.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(update.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createchitet.pending, (state) => {
        state.loading = true;
      })
      .addCase(createchitet.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createchitet.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getdsdexuatbyid.pending, (state) => {
        state.loading = true;
      })
      .addCase(getdsdexuatbyid.fulfilled, (state, action) => {
        state.loading = false;
        state.chitietkehoachmuahanglist = action.payload;
        let tongtiendexuat_ = 0,
          tongtienduocduyet_ = 0;
        for (
          let index = 0;
          index < state.chitietkehoachmuahanglist.length;
          index++
        ) {
          tongtiendexuat_ +=
            state.chitietkehoachmuahanglist[index].soluong *
            state.chitietkehoachmuahanglist[index].giadukien;
          tongtienduocduyet_ +=
            state.chitietkehoachmuahanglist[index].soluongduyet *
            state.chitietkehoachmuahanglist[index].giadukien;
        }
        state.tongtiendexuat = tongtiendexuat_;
        state.tongtienduocduyet =
          tongtienduocduyet_ > 0 ? tongtienduocduyet_ : 0;
      })
      .addCase(getdsdexuatbyid.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(deleteDeXuatChiTiet.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteDeXuatChiTiet.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteDeXuatChiTiet.pending, (state) => {
        state.loading = true;
      })

      .addCase(getDmHangHoa.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getDmHangHoa.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getDmHangHoa.pending, (state) => {
        state.loading = true;
      })
      .addCase(addrow.pending, (state) => {
        state.loading = true;
      })
      .addCase(addrow.fulfilled, (state, action) => {
        if (action.payload) {
          state.chitietkehoachmuahanglist = [
            action.payload,
            ...state.chitietkehoachmuahanglist,
          ];
        }
      })
      .addCase(addrow.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getGiaNhapGanNhat.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getGiaNhapGanNhat.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(putXacNhanChiTiet.pending, (state) => {
        state.loading = true;
      })
      .addCase(putXacNhanChiTiet.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(putXacNhanChiTiet.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  showLoading,
  kehoachmuahanglist,
  kehoachmuahangdetail,
  addRowChiTietkehoachmuahang,
  reSetDataChiTietkehoachmuahang,
  addRow,
  updateRowChiTietDeXuat,
  bangchitietkehoachmuahang,
  thongTinCheckXuLyDonHang,
} = kehoachmuahangSlice.actions;

export default kehoachmuahangSlice.reducer;
