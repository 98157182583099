import React from "react"

const ChevronSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill="#616161"
      d="m.206 4.904.588-.808L6 7.882l5.206-3.787.588.809-5.5 4a.5.5 0 0 1-.588 0l-5.5-4Z"
    />
  </svg>
)
export default ChevronSvg