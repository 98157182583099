import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import deNghiThuTienService from "services/Finance/DeNghiThuTienService";
import { cloneDeep } from "lodash";

export const getList = createAsyncThunk(
  "deNghiThuTien/getList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deNghiThuTienService.Get(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getById = createAsyncThunk(
  "deNghiThuTien/getById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deNghiThuTienService.GetById(id);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const create = createAsyncThunk(
  "deNghiThuTien/create",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await deNghiThuTienService.Create(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);

export const searchCty = createAsyncThunk(
  "deNghiThuTien/searchCty",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deNghiThuTienService.GetDsCty();
      return response;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const deleteDeNghiThuTien = createAsyncThunk(
  "deNghiThuTien/deleteDeNghiThuTien",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await deNghiThuTienService.Delete(payload.id);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const update = createAsyncThunk(
  "deNghiThuTien/update",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await deNghiThuTienService.Update(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);

export const createChiTiet = createAsyncThunk(
  "deNghiThuTien/createChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await deNghiThuTienService.CreateChiTiet(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);

export const updateChiTiet = createAsyncThunk(
  "deNghiThuTien/updateChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await deNghiThuTienService.UpdateChiTiet(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);

export const deleteDeNghiThuTienChiTiet = createAsyncThunk(
  "deNghiThuTien/deleteDeNghiThuTienChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await deNghiThuTienService.DeleteChiTiet(payload.id);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const addrow = createAsyncThunk(
  "dexuatmuasam/addrow",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      let item = state.deNghiThuTien.denghiThuTienChiTietList.find(
        (x) => x.id === null
      );
      if (data && !item) {
        const newData = {
          id: null,
          dienGiai: null,
          danhMucThuId: null,
          donGia: null,
          soLuong: null,
          thanhTien: null,
          ghiChu: null,
        };
        return newData;
      }
      return rejectWithValue();
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getDsChiTietByDeNghiThuTienId = createAsyncThunk(
  "deNghiThuTien/getDsChiTietByDeNghiThuTienId",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deNghiThuTienService.GetDsChiTietByDeNghiThuTienId(
        id
      );
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const createPhieuThu = createAsyncThunk(
  "deNghiThuTien/createPhieuThu",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await deNghiThuTienService.CreatPhieuThu(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);

export const tinhTongTienDeNghiThu = createAsyncThunk(
  "deNghiThuTien/tinhTongTienDeNghiThu",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deNghiThuTienService.TinhTongTienDeNghiThu(id);
      if (!response.data.isOk)
        return rejectWithValue(response.data.description || "Error");
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  deNghiThuTienList: [],
  deNghiThuTienDetail: {},
  denghiThuTienChiTietList: [],
  totalCount: 0,
  totalPages: 0,
  dsCty: [],
  totalPagesDsCty: 0,
};

const deNghiThuTienSlice = createSlice({
  name: "deNghiThuTien",
  initialState,
  reducers: {
    reSetDataChiTietDeNghiThuTien: (state) => {
      state.denghiThuTienChiTietList = [];
    },
    updateRowChiTietDeNghiThuTien: (state, action) => {
      var data = cloneDeep(action.payload);
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      delete payload.onError;
      state.denghiThuTienChiTietList = payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.loading = false;
        state.deNghiThuTienList = action.payload;
      })
      .addCase(getList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(create.pending, (state) => {
        state.loading = true;
      })
      .addCase(create.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(create.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getById.fulfilled, (state, action) => {
        state.loading = false;
        state.deNghiThuTienDetail = action.payload;
      })
      .addCase(getById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(searchCty.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchCty.fulfilled, (state, action) => {
        state.loading = false;
        state.dsCty = action.payload;
      })
      .addCase(searchCty.rejected, (state) => {
        state.loading = false;
      })
      .addCase(update.pending, (state) => {
        state.loading = true;
      })
      .addCase(update.fulfilled, (state, action) => {
        state.loading = false;
        state.deNghiThuTienDetail = action.payload.data;
      })
      .addCase(update.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteDeNghiThuTien.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDeNghiThuTien.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteDeNghiThuTien.rejected, (state) => {
        state.loading = false;
      })

      .addCase(createChiTiet.pending, (state) => {
        state.loading = true;
      })
      .addCase(createChiTiet.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createChiTiet.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateChiTiet.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateChiTiet.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateChiTiet.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteDeNghiThuTienChiTiet.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDeNghiThuTienChiTiet.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteDeNghiThuTienChiTiet.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addrow.pending, (state) => {
        state.loading = true;
      })
      .addCase(addrow.fulfilled, (state, action) => {
        if (action.payload) {
          state.denghiThuTienChiTietList = [
            action.payload,
            ...state.denghiThuTienChiTietList,
          ];
        }
      })
      .addCase(addrow.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getDsChiTietByDeNghiThuTienId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDsChiTietByDeNghiThuTienId.fulfilled, (state, action) => {
        state.loading = false;
        state.denghiThuTienChiTietList = action.payload;
      })
      .addCase(createPhieuThu.pending, (state, action) => {
        state.loading = false;
      })
      .addCase(createPhieuThu.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createPhieuThu.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(tinhTongTienDeNghiThu.pending, (state, action) => {
        state.loading = false;
      })
      .addCase(tinhTongTienDeNghiThu.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(tinhTongTienDeNghiThu.rejected, (state, action) => {
        state.loading = false;
      });
  },
});
export const { updateRowChiTietDeNghiThuTien, reSetDataChiTietDeNghiThuTien } =
  deNghiThuTienSlice.actions;

export default deNghiThuTienSlice.reducer;
