import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { cloneDeep } from "lodash";
import chucDanhCategoryService from "services/thietLapKhac/ChucDanhCategoryService";

export const getDanhMucChucDanh = createAsyncThunk(
  "chucDanhCategory/getDanhMucChucDanh",
  async (branchId, { rejectWithValue }) => {
    try {
      const response = await chucDanhCategoryService.Get(branchId);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateDanhMucChucDanh = createAsyncThunk(
  "chucDanhCategory/updateDanhMucChucDanh",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await chucDanhCategoryService.Update(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createDanhMucChucDanh = createAsyncThunk(
  "chucDanhCategory/createDanhMucChucDanh",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await chucDanhCategoryService.Create(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const deleteDanhMucChucDanh = createAsyncThunk(
  "chucDanhCategory/deleteDanhMucChucDanh",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await chucDanhCategoryService.Delete(id);
      if (onSuccess) onSuccess(response);
      return id;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  danhMucChucDanhList: [],
  danhMucChucDanhListSetting: [],
  error: null,
};

const chucDanhCategorySlice = createSlice({
  name: "chucDanhCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDanhMucChucDanh.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDanhMucChucDanh.fulfilled, (state, action) => {
        state.loading = false;
        const payload = action.payload || [];
        const filteredPayload = payload.filter(
          (item) => item.IsActivated === true
        );
        state.danhMucChucDanhList = filteredPayload;
        state.danhMucChucDanhListSetting = [
          {
            action: "initial",
            isRequired: true,
          },
          ...payload,
        ];
      })
      .addCase(getDanhMucChucDanh.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateDanhMucChucDanh.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDanhMucChucDanh.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateDanhMucChucDanh.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createDanhMucChucDanh.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDanhMucChucDanh.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createDanhMucChucDanh.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteDanhMucChucDanh.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDanhMucChucDanh.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteDanhMucChucDanh.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default chucDanhCategorySlice.reducer;
