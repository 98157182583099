import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import trainingCenterService from "services/Training/TrainingCenterService";
import { cloneDeep } from "lodash";

export const getTrainingCenters = createAsyncThunk(
  "trainingCenters/getTrainingCenters",
  async (branchId, { rejectWithValue }) => {
    try {
      const response = await trainingCenterService.Get(branchId);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateTrainingCenter = createAsyncThunk(
  "trainingCenters/updateTrainingCenter",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await trainingCenterService.Update(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createTrainingCenter = createAsyncThunk(
  "trainingCenters/createTrainingCenter",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await trainingCenterService.Create(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const deleteTrainingCenter = createAsyncThunk(
  "trainingCenters/deleteTrainingCenter",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await trainingCenterService.Delete(id);
      if (onSuccess) onSuccess(response);
      return id;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  trainingCenterList: [],
  trainingCenterListSetting: [],
  error: null,
};

const trainingCentersSlice = createSlice({
  name: "trainingCenters",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTrainingCenters.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTrainingCenters.fulfilled, (state, action) => {
        state.loading = false;
        const payload = action.payload || [];
        const filteredPayload = payload.filter(
          (item) => item.inActive === false
        );
        state.trainingCenterList = filteredPayload;
        state.trainingCenterListSetting = [
          {
            action: "initial",
            isRequired: true,
          },
          ...payload,
        ];
      })
      .addCase(getTrainingCenters.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateTrainingCenter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTrainingCenter.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateTrainingCenter.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createTrainingCenter.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTrainingCenter.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createTrainingCenter.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteTrainingCenter.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTrainingCenter.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteTrainingCenter.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default trainingCentersSlice.reducer;
