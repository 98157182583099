import { Button, Input, Popconfirm, Table } from "antd";

import React, { useMemo, useState } from "react";
import { styled } from "styled-components";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  createDmdonvitinh,
  setAllDmdonvitinhList,
  updateDmdonvitinh,
  deleteDmdonvitinh,
} from "store/slices/sale/danhmucSlice";

import { initDmkk } from "views/app-views/sale/category/Dmkk";

const TableDmdonvitinhList = ({
  dmDonViTinhList,
  reloadData,
  permitList = [],
}) => {
  const [editingKey, setEditingKey] = useState("");
  //const isEditing = (record) => record.key === editingKey;

  const dispatch = useDispatch();

  const onChangeText = (type, record) => (e) => {
    const newData = dmDonViTinhList.slice(0);
    const value = e?.target?.value ?? e;
    const index1 = newData.findIndex(
      (item) => item.madonvitinh === record.madonvitinh
    );
    newData[index1] = { ...newData[index1], [type]: value };
    dispatch(setAllDmdonvitinhList(newData));
  };
  const onClickAction = (record, index) => {
    const newData = dmDonViTinhList.slice(0);
    const payload = {
      madonvitinh: record.madonvitinh,
      tendonvitinh: record.tendonvitinh,

      onSuccess: () => {
        reloadData();
      },
    };
    if (record.action === initDmkk.action) {
      dispatch(createDmdonvitinh(payload));
    } else {
      if (record.madonvitinh !== null && record.action !== "update") {
        const index1 = newData.findIndex(
          (item) => item.madonvitinh === record.madonvitinh
        );
        newData[index1] = { ...newData[index1], action: "update" };
        dispatch(setAllDmdonvitinhList(newData));
      } else {
        dispatch(updateDmdonvitinh(payload));
      }
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        title: "STT",
        width: 50,
        align: "center",
        render: (_, __, index) => index + 1,
      },
      {
        title: "Mã số",
        width: 90,
        dataIndex: "madonvitinh",
      },
      {
        title: "Tên",
        width: 180,
        dataIndex: "tendonvitinh",
        render: (_, record, index) =>
          record.action ? (
            <Input
              style={{ width: "100%" }}
              value={record.tendonvitinh}
              placeholder="Nhập tên..."
              onChange={onChangeText("tendonvitinh", record)}
            />
          ) : (
            record.tendonvitinh
          ),
      },
      {
        fixed: "right",
        width: 90,
        align: "center",
        dataIndex: "action",
        render: (_, record, index) => (
          <>
            {record.action === "initial" ? (
              <Button
                //disabled={!record.tenhh || !allowView(PermitValue.them)}
                title={"Thêm thông tin"}
                onClick={() => onClickAction(record, index)}
                className="mr-2"
                icon={<PlusOutlined />}
                shape="circle"
              />
            ) : (
              <>
                <Button
                  disabled={
                    editingKey !== "" && editingKey !== record.madonvitinh
                  }
                  title={"Sửa thông tin"}
                  //disabled={!allowView(PermitValue.sua)}
                  onClick={() => {
                    setEditingKey(editingKey !== "" ? "" : record.madonvitinh);
                    onClickAction(record, index);
                  }}
                  className="mr-2"
                  icon={
                    record.action === "update" ? (
                      <SaveOutlined />
                    ) : (
                      <EditOutlined />
                    )
                  }
                  shape="circle"
                />
                <Popconfirm
                  title="Bạn có muốn xóa không?"
                  placement="topLeft"
                  onConfirm={() => {
                    dispatch(
                      deleteDmdonvitinh({
                        id: record.madonvitinh,
                        onSuccess: (r) => {
                          reloadData();
                        },
                      })
                    );
                  }}
                >
                  <Button
                    disabled={editingKey !== ""}
                    title={"Xóa thông tin"}
                    //disabled={!allowView(PermitValue.xoa)}
                    className="mr-2"
                    icon={<DeleteOutlined type="primary" />}
                    shape="circle"
                  />
                </Popconfirm>
              </>
            )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dmDonViTinhList]
  );

  return (
    <CustomTable
      columns={tableColumns}
      dataSource={dmDonViTinhList}
      rowKey={(item) => item?.madonvitinh}
      bordered
      style={{ padding: "0px" }}
      fixed={true}
      scroll={{ x: "max-content" }}
    />
  );
};

const CustomTable = styled(Table)`
  .ant-table-tbody > tr > td {
    padding: 2px;
  }
`;

export default TableDmdonvitinhList;
