import axiosInstance from "auth/FetchInterceptor";
import { TONGHOPDEXUATMUASAM_API } from "constants/ApiConstant";
const TongHopDeXuatMuaSamService = {};

// start dexuatmuasam

TongHopDeXuatMuaSamService.GetAllTongHop = function (data) {
  return axiosInstance.post(
    `${TONGHOPDEXUATMUASAM_API}/sale/get-all-de-xuat-mua-sam`,
    data,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};
TongHopDeXuatMuaSamService.CapNhatTongHop = function (data) {
  return axiosInstance.post(
    `${TONGHOPDEXUATMUASAM_API}/sale/cap-nhat-de-xuat-mua-sam`,
    data,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.GetTongHop = function (data) {
  return axiosInstance.get(
    `${TONGHOPDEXUATMUASAM_API}/sale/get-de-xuat-mua-sam?id=${data}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.GetTongHopChiTiet = function (id) {
  return axiosInstance.get(
    `${TONGHOPDEXUATMUASAM_API}/sale/get-de-xuat-mua-sam-chi-tiet?id=${id}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};
TongHopDeXuatMuaSamService.CreateDeXuatChiTietDuyet = function (data) {
  return axiosInstance.post(
    `${TONGHOPDEXUATMUASAM_API}/sale/de-xuat-duyet-chi-tiet-create`,
    data,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};
TongHopDeXuatMuaSamService.UpdateDeXuatChiTietDuyet = function (data) {
  return axiosInstance.post(
    `${TONGHOPDEXUATMUASAM_API}/sale/de-xuat-duyet-chi-tiet-update`,
    data,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.GetDeXuatChiTietDuyet = function (data) {
  return axiosInstance.post(
    `${TONGHOPDEXUATMUASAM_API}/sale/get-de-xuat-chi-tiet-duyet`,
    data,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.TaoBanSaoDeXuatChiTietDuyet = function (id) {
  return axiosInstance.get(
    `${TONGHOPDEXUATMUASAM_API}/sale/tao-ban-sao-de-xuat-chi-tiet-duyet?id=${id}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.duyetDeXuat = function (data) {
  return axiosInstance.post(
    `${TONGHOPDEXUATMUASAM_API}/sale/duyet-de-xuat-mua-sam`,
    data,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};
// end dexuatmuasam

export default TongHopDeXuatMuaSamService;
