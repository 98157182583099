import {
  GET_MEAL_ALLOWANCE_API,
  UPDATE_MEAL_ALLOWANCE_API,
  CREATE_MEAL_ALLOWANCE_API,
  DELETE_MEAL_ALLOWANCE_API,
  GET_MEAL_ALLOWANCE_BY_ID_API,
  CREATE_MA_DETAIL_API,
  GET_MA_DETAIL_API,
  UPDATE_MA_DETAIL_API,
  DELETE_MA_DETAIL_API,
  UPDATE_MA_TOTAL_EMPLOYEE_API,
  CREATE_DANG_KY_CONG_COM_API,
  GET_DANG_KY_CONG_COM_API,
  GET_DANG_KY_CONG_COM_BY_ID_API,
  FIND_STT_CHOT_CONG_API,
  CHOT_CONG_COM_API,
  HUY_CONG_COM_BY_HR,
  NAP_DU_LIEU_COM_THANG_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const mealAllowanceService = {};

mealAllowanceService.Get = function (data) {
  return axiosInstance.post(`${GET_MEAL_ALLOWANCE_API}`, data);
};
mealAllowanceService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_MEAL_ALLOWANCE_API}`, data);
};
mealAllowanceService.Create = function (data) {
  return axiosInstance.post(`${CREATE_MEAL_ALLOWANCE_API}`, data);
};
mealAllowanceService.Delete = function (data) {
  return axiosInstance.delete(`${DELETE_MEAL_ALLOWANCE_API}?id=${data}`);
};
mealAllowanceService.GetById = function (data) {
  return axiosInstance.get(`${GET_MEAL_ALLOWANCE_BY_ID_API}?id=${data}`);
};
mealAllowanceService.CreateDetail = function (data) {
  return axiosInstance.post(`${CREATE_MA_DETAIL_API}`, data);
};

mealAllowanceService.GetDetail = function (id, searchText) {
  return axiosInstance.get(
    `${GET_MA_DETAIL_API}?id=${id}&searchText=${searchText}`
  );
};
mealAllowanceService.UpdateDetail = function (data) {
  return axiosInstance.put(`${UPDATE_MA_DETAIL_API}`, data);
};

mealAllowanceService.DeleteDetail = function (data) {
  return axiosInstance.delete(`${DELETE_MA_DETAIL_API}?id=${data}`);
};
mealAllowanceService.UpdateMATotalEmployee = function (data) {
  return axiosInstance.put(`${UPDATE_MA_TOTAL_EMPLOYEE_API}?id=${data}`);
};
mealAllowanceService.CreateDangKyCongCom = function (data) {
  return axiosInstance.post(`${CREATE_DANG_KY_CONG_COM_API}`, data);
};
mealAllowanceService.GetDangKyCongCom = function (data) {
  return axiosInstance.post(`${GET_DANG_KY_CONG_COM_API}`, data);
};
mealAllowanceService.GetDangKyCongComById = function (data) {
  return axiosInstance.get(`${GET_DANG_KY_CONG_COM_BY_ID_API}?id=${data}`);
};
mealAllowanceService.FindStatusChotCong = function (data) {
  return axiosInstance.post(`${FIND_STT_CHOT_CONG_API}`, data);
};
mealAllowanceService.ChotCongComTheoNgay = function (data) {
  return axiosInstance.post(`${CHOT_CONG_COM_API}`, data);
};
mealAllowanceService.HuyCongComByHr = function (data) {
  return axiosInstance.post(`${HUY_CONG_COM_BY_HR}`, data);
};
mealAllowanceService.NapDuLieuCongComTheoThang = function (data) {
  return axiosInstance.post(`${NAP_DU_LIEU_COM_THANG_API}`, data);
};
export default mealAllowanceService;
