import axiosInstance from "auth/FetchInterceptor";
import {
  ADD_ROLE_API,
  DELETE_ROLE_API,
  ROLE_BY_SCOPE_API,
  ROLE_DETAIL_API,
  UPDATE_ROLE_API,
  UPDATE_ROLE_PERMIT_API,
} from "constants/ApiConstant";
const RolePermitService = {};

RolePermitService.getRoleByScope = function (id) {
  return axiosInstance.get(`${ROLE_BY_SCOPE_API}/${id}`, {
    baseURL: process.env.REACT_APP_IDENTITY_ENDPOINT_URL,
  });
};
RolePermitService.getRoleDetail = function (data) {
  return axiosInstance.get(
    `${ROLE_DETAIL_API}?roleId=${data.roleId}&scopeId=${data.scopeId}`,
    {
      baseURL: process.env.REACT_APP_IDENTITY_ENDPOINT_URL,
    }
  );
};
RolePermitService.addRole = function (data) {
  return axiosInstance.post(ADD_ROLE_API, data, {
    baseURL: process.env.REACT_APP_IDENTITY_ENDPOINT_URL,
  });
};
RolePermitService.updateRole = function (data) {
  return axiosInstance.put(UPDATE_ROLE_API, data, {
    baseURL: process.env.REACT_APP_IDENTITY_ENDPOINT_URL,
  });
};
RolePermitService.updateRolePermit = function (data) {
  return axiosInstance.put(UPDATE_ROLE_PERMIT_API, data, {
    baseURL: process.env.REACT_APP_IDENTITY_ENDPOINT_URL,
  });
};

RolePermitService.deleteRole = function (id) {
  return axiosInstance.delete(`${DELETE_ROLE_API}/${id}`, {
    baseURL: process.env.REACT_APP_IDENTITY_ENDPOINT_URL,
  });
};

export default RolePermitService;
