import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import DeNghiTamUngService from "services/Finance/DeNghiTamUngService";

export const GetAllDeNghiTamUng = createAsyncThunk(
  "dinhKhoan/GetAllDeNghiTamUng",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DeNghiTamUngService.GetAllDeNghiTamUng(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetDeNghiTamUng = createAsyncThunk(
  "dinhKhoan/GetDeNghiTamUng",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DeNghiTamUngService.GetDeNghiTamUng(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertDeNghiTamUng = createAsyncThunk(
  "dinhKhoan/UpsertDeNghiTamUng",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeNghiTamUngService.UpsertDeNghiTamUng(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeleteDeNghiTamUng = createAsyncThunk(
  "dinhKhoan/DeleteDeNghiTamUng",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeNghiTamUngService.DeleteDeNghiTamUng(payload.id);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const GetDeNghiTamUngChiTiet = createAsyncThunk(
  "dinhKhoan/GetDeNghiTamUngChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DeNghiTamUngService.GetDeNghiTamUngChiTiet(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertDeNghiTamUngChiTiet = createAsyncThunk(
  "dinhKhoan/UpsertDeNghiTamUngChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeNghiTamUngService.UpsertDeNghiTamUngChiTiet(
        data
      );
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeleteDeNghiTamUngChiTiet = createAsyncThunk(
  "dinhKhoan/DeleteDeNghiTamUngChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeNghiTamUngService.DeleteDeNghiTamUngChiTiet(
        payload.id
      );
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  DeNghiUngList: [],
  DeNghiUngDetail: {},
  DeNghiUngChiTietList: [],
};

const deNghiUngSlice = createSlice({
  name: "DeNghiUng",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //GetDeNghiTamUngChiTiet
      .addCase(GetDeNghiTamUngChiTiet.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetDeNghiTamUngChiTiet.fulfilled, (state, action) => {
        state.loading = false;
        state.DeNghiUngChiTietList = action.payload;
      })
      .addCase(GetDeNghiTamUngChiTiet.rejected, (state) => {
        state.loading = false;
      })
      //GetDeNghiTamUng
      .addCase(GetDeNghiTamUng.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetDeNghiTamUng.fulfilled, (state, action) => {
        state.loading = false;
        state.DeNghiUngDetail = action.payload;
      })
      .addCase(GetDeNghiTamUng.rejected, (state) => {
        state.loading = false;
      })
      //GetAllDeNghiTamUng
      .addCase(GetAllDeNghiTamUng.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllDeNghiTamUng.fulfilled, (state, action) => {
        state.loading = false;
        state.DeNghiUngList = action.payload;
      })
      .addCase(GetAllDeNghiTamUng.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { showLoading, setHisInfo } = deNghiUngSlice.actions;

export default deNghiUngSlice.reducer;
