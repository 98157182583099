import axiosInstance from "auth/FetchInterceptor";
import { RATING_SUBMIT_API, RATING_GETBYCODE_API, ESIGN_CHECKTHE_API, ESIGN_SUBMIT_API } from "constants/ApiConstant";
const RatingService = {};

RatingService.ratingGetByCodeAPI = function (code) {
    return axiosInstance.get(`${RATING_GETBYCODE_API}?code=${code}`, {
        baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    });
};
RatingService.ratingSubmitAPI = function (data) {
    return axiosInstance.post(RATING_SUBMIT_API, data, {
        baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    });
};

RatingService.checkTheAPI = function (data) {
    return axiosInstance.post(ESIGN_CHECKTHE_API, data, {
        baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    });
};


RatingService.esignSubmit = function (data) {
    return axiosInstance.post(ESIGN_SUBMIT_API, data, {
        baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    });
};


export default RatingService;