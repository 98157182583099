import axiosInstance from "auth/FetchInterceptor";
import {
  DOCTOR_MANAGEMENT_API,
  HOSPITAL_MANAGER_API,
} from "constants/ApiConstant";
const DoctorManagementService = {};

DoctorManagementService.getAllDoctor = function (data) {
  return axiosInstance.post(`${DOCTOR_MANAGEMENT_API}/search-grid`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DoctorManagementService.getAllHospital = function () {
  return axiosInstance.get(`${HOSPITAL_MANAGER_API}/get-all`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DoctorManagementService.getDoctorById = function (id) {
  return axiosInstance.get(`${DOCTOR_MANAGEMENT_API}/get-by-id?id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DoctorManagementService.create = function (data) {
  return axiosInstance.post(DOCTOR_MANAGEMENT_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DoctorManagementService.update = function (data) {
  return axiosInstance.put(DOCTOR_MANAGEMENT_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DoctorManagementService.changeStateDoctor = function (data) {
  return axiosInstance.put(`${DOCTOR_MANAGEMENT_API}/change-state`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DoctorManagementService.delete = function (id) {
  return axiosInstance.delete(`${DOCTOR_MANAGEMENT_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DoctorManagementService.deletePatientCommentById = function (id) {
  return axiosInstance.delete(
    `${DOCTOR_MANAGEMENT_API}/delete-comment-by-id?id=${id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

DoctorManagementService.getPatientCommentById = function (id) {
  return axiosInstance.get(
    `${DOCTOR_MANAGEMENT_API}/get-comment-by-id?id=${id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

export default DoctorManagementService;
