import {
  GET_DOCUMENT_SETTING_API,
  UPDATE_DOCUMENT_SETTING_API,
  CREATE_DOCUMENT_SETTING_API,
  DELETE_DOCUMENT_SETTING_API,
  GET_DOCUMENT_MANAGE_API,
  GET_DOCUMENT_MANAGE_BY_ID_API,
  CREATE_DOCUMENT_MANAGE_API,
  UPDATE_DOCUMENT_MANAGE_API,
  DELETE_DOCUMENT_MANAGE_API,
  DELETE_REGULATION_GROUP_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const documentService = {};

documentService.GetSetting = function (data) {
  return axiosInstance.get(`${GET_DOCUMENT_SETTING_API}?branchId=${data.branchId}&type=${data.type}`, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
documentService.UpdateSetting = function (data) {
  return axiosInstance.put(`${UPDATE_DOCUMENT_SETTING_API}`, data, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
documentService.CreateSetting = function (data) {
  return axiosInstance.post(`${CREATE_DOCUMENT_SETTING_API}`, data, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
documentService.DeleteSetting = function (data) {
  return axiosInstance.delete(`${DELETE_DOCUMENT_SETTING_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
documentService.DeleteRegulationGroup = function (data) {
  return axiosInstance.delete(`${DELETE_REGULATION_GROUP_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};

documentService.GetManage = function (data) {
  return axiosInstance.post(`${GET_DOCUMENT_MANAGE_API}`, data, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
documentService.GetManageById = function (data) {
  return axiosInstance.get(`${GET_DOCUMENT_MANAGE_BY_ID_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
documentService.UpdateManage = function (data) {
  return axiosInstance.put(`${UPDATE_DOCUMENT_MANAGE_API}`, data, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
documentService.CreateManage = function (data) {
  return axiosInstance.post(`${CREATE_DOCUMENT_MANAGE_API}`, data, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
documentService.DeleteManage = function (data) {
  return axiosInstance.delete(`${DELETE_DOCUMENT_MANAGE_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};

export default documentService;
