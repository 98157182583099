import React, { useEffect, useState } from "react";
import { Button, Form, Input, InputNumber, Table } from "antd";
import {
  getHangSapHetTon,
  updateHangSapHetTon,
} from "store/slices/sale/danhmucSlice";
import { useDispatch, useSelector } from "react-redux";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { formatMoney } from "utils/helper";
export const initHangSapHetTon = {
  action: "initial",
  isRequired: true,
};
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const DmhanghettonList = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { allHangSapHetTonList, loading } = useSelector(
    (state) => state.danhMucSale
  );
  useEffect(() => {
    dispatch(getHangSapHetTon());
  }, [dispatch]);

  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.mahh === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingKey(record.mahh);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (record) => {
    try {
      const row = await form.validateFields();

      const payload = {
        mahh: record.mahh,
        saphetton: row.saphetton,
        onSuccess: () => {
          dispatch(getHangSapHetTon());
        },
      };

      dispatch(updateHangSapHetTon(payload));
      setEditingKey("");
    } catch (errInfo) {}
  };
  const columns = [
    {
      title: "Mã",
      dataIndex: "mahh",
      width: "6%",
      editable: false,
    },
    {
      title: "Mã hàng",
      dataIndex: "maview",
      width: "9%",
      editable: false,
    },
    {
      title: "Tên hàng",
      dataIndex: "tenhh",
      editable: false,
    },
    {
      title: "Đơn giá",
      width: "10%",
      editable: false,
      align: "right",
      render: (_, r) => formatMoney(r.dongia),
    },
    {
      title: "Sắp hết tồn",
      dataIndex: "saphetton",
      width: "10%",
      editable: true,
    },
    {
      title: "Cập nhật",
      dataIndex: "operation",
      width: "10%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Button
            icon={<SaveOutlined />}
            onClick={() => save(record)}
            className="mr-2"
            shape="circle"
          />
        ) : (
          <Button
            icon={<EditOutlined />}
            onClick={() => edit(record)}
            className="mr-2"
            shape="circle"
          />
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <h3>Thiết lập sắp hết tồn</h3>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          loading={loading}
          bordered
          dataSource={allHangSapHetTonList}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
    </>
  );
};
export default DmhanghettonList;
